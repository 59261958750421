export const assertNever = (n: never) => n;

export const tryParseJSON = (jsonString: string): any => {
    try {
        return JSON.parse(jsonString);
    } catch {
        return null;
    }
};

const emailRegex = /^\S+@\S+\.\S+$/;

export const isEmail = (value: string) => {
    return emailRegex.test(value);
};

export const isEmailList = (value: string) => {
    if (!value) return false;
    return value.split(/\s*,\s*/).every(v => emailRegex.test(v));
};

const dataMarkerRegex = /^[a-z0-9.]+$/i;

export const isDataMarker = (value: string) => {
    if (!value) return false;
    return dataMarkerRegex.test(value);
};

export const preventDefault = (fn: () => void) => (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.preventDefault();
    fn();
};

export const isPlainObject = (value: unknown) => value?.constructor === Object;

export const joinWithOr = (array: string[]) => {
    if (array.length === 0) {
        return "";
    }

    if (array.length === 1) {
        return array[0];
    }

    return array.slice(0, -1).join(", ") + " or " + array.at(-1);
};
