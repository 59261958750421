import { openDB } from "idb";

interface FileDbRecord {
    id: string;
    sessionKey: string;
    dataMarker: string;
    files: readonly File[];
}

const getDb = () => {
    return openDB("intouch-form-files-v2", 1, {
        upgrade(db) {
            const store = db.createObjectStore("files", { keyPath: "id" });
            store.createIndex("sessionKey", "sessionKey", { unique: false });
        }
    });
};

export default {
    save: async (sessionKey: string, dataMarker: string, files: readonly File[]) => {
        const db = await getDb();
        const transaction = db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");

        const record: FileDbRecord = {
            id: sessionKey + "-" + dataMarker,
            sessionKey,
            dataMarker,
            files
        };

        await store.put(record);
        await transaction.done;
    },
    get: async (sessionKey: string) => {
        const db = await getDb();
        const transaction = db.transaction("files", "readonly");
        const store = transaction.objectStore("files");
        const records = (await store.index("sessionKey").getAll(sessionKey)) as FileDbRecord[];
        await transaction.done;
        return records;
    },
    remove: async (sessionKey: string) => {
        const db = await getDb();
        const transaction = db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        const all = (await store.index("sessionKey").getAll(sessionKey)) as FileDbRecord[];
        const promises = all.map(r => store.delete(r.id));
        await Promise.all(promises);
        await transaction.done;
    }
};
