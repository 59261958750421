import { isStringArray } from "@forms/helpers/utils";
import { assertNever, dateTimeFn, Dictionary } from "@common";
import { CurrencyInputValue, NumberInputValue } from "../components/components.types";
import {
    FieldState,
    FormState,
    FieldValue,
    QuestionSchema,
    AddressQuestionSchema,
    RadioListQuestionSchema,
    DropdownListQuestionSchema,
    FileFieldValue,
    MultiselectFieldValue,
    TextFieldValue,
    YesNoFieldValue,
    CheckboxFieldValue,
    DateFieldValue,
    DropdownListFieldValue,
    RadioListFieldValue,
    MultiselectQuestionSchema,
    FormModel,
    TeamServerModel,
    EsignatureFieldValue,
    PersistedFormState,
    PersistedFieldState,
    FeeEarnerFieldValue
} from "../types";
import { convertAddressQuestionToTextQuestions } from "@forms/helpers/questionFn";
import formStateStorage from "./formStateStorage";
import { PointGroup } from "signature_pad";
import service from "@forms/services/service";

export const createFormState = async (
    form: FormModel,
    matterGuid: string,
    previousCompletionGuid: string
): Promise<[FormState, TeamServerModel[]]> => {
    const [teams, matterData, previousSubmissionData] = await Promise.all([
        service.fetchTeamsIfNecessary(form),
        service.fetchMatterAnswerDataIfNecessary(form.guid, matterGuid),
        service.fetchPreviousSubmissionDataIfNecessary(form.guid, matterGuid, previousCompletionGuid)
    ]);

    const persistedFormState = await formStateStorage.read(form.guid, matterGuid);

    const seedFormData = { ...matterData, ...previousSubmissionData };

    const entries = form.schema.steps
        .flatMap(s => s.blocks)
        .flatMap(b =>
            b.type === "question" ? createFieldStatesInner(b.question, teams, persistedFormState, seedFormData) : []
        );

    const formState: FormState = Object.fromEntries(entries);
    return [formState, teams];
};

export const createFieldStates = (qs: QuestionSchema) => createFieldStatesInner(qs);

const createFieldStatesInner = (
    qs: QuestionSchema,
    teams?: TeamServerModel[],
    persistedFormState?: PersistedFormState,
    seedFormData?: Dictionary<unknown>
): [string, FieldState][] => {
    switch (qs.type) {
        case "address":
            return convertAddressQuestionToTextQuestions(qs).map(tqs =>
                createFieldStateEntry(tqs, teams, persistedFormState, seedFormData)
            );
        default:
            return [createFieldStateEntry(qs, teams, persistedFormState, seedFormData)];
    }
};

const createFieldStateEntry = (
    questionSchema: Exclude<QuestionSchema, AddressQuestionSchema>,
    teams: TeamServerModel[],
    persistedFormState: PersistedFormState,
    seedFormData: Dictionary<unknown>
): [string, FieldState] => {
    const fieldState = persistedFormState?.[questionSchema.dataMarker];
    const seedFormValue = seedFormData?.[questionSchema.dataMarker];
    const fieldValue = getFieldValue(questionSchema, teams, fieldState, seedFormValue);
    const newFieldState = { value: fieldValue, isTouched: fieldState?.isTouched ?? false };
    return [questionSchema.dataMarker, newFieldState];
};

// This function does a couple important things:
// 1. It prioritizes data drawn from storage over data passed as seed data,
//    even if the value persisted to storage was an empty string or a null.
// 2. Even when a value is drawn from storage, it first checks if that value has an appropriate type
//    for the question at hand.
//    How could a value persisted to storage be wrong?
//    Well, form schema might change after the value was persisted
//    and a question that was of type "text" might become a question that is of type "checkbox"
//    this logic makes the app tolerant to such scenarios.
//    At the time of writing, the UI makes it impossible to change the question type after it was created
//    but it's still possible to bypass this by deleting the question and creating a new one with the same
//    data marker and a different type.
//    The fun of maintaining a database with centralized schema but distributed storage for you.
const getFieldValue = (
    questionSchema: Exclude<QuestionSchema, AddressQuestionSchema>,
    teams: TeamServerModel[],
    persistedFieldState: PersistedFieldState,
    seedFormValue: unknown
): FieldValue => {
    switch (questionSchema.type) {
        case "text":
        case "textarea":
        case "email":
        case "phone-number":
        case "time":
            return new TextFieldValue(getStringValue(), questionSchema.type);
        case "yes-no":
            return new YesNoFieldValue(getBooleanValue());
        case "radio-list":
            return new RadioListFieldValue(...getRadioListValue(questionSchema));
        case "currency":
            return new CurrencyInputValue(getStringValue());
        case "file":
            return new FileFieldValue(getFilesValue());
        case "checkbox":
            return new CheckboxFieldValue(getBooleanValue());
        case "dropdown-list":
            return new DropdownListFieldValue(...getDropdownListValue(questionSchema));
        case "multiselect":
            return new MultiselectFieldValue(...getMultiselectListValue(questionSchema));
        case "number":
            return new NumberInputValue(getStringValue());
        case "date":
            return new DateFieldValue(getDateValue(), false);
        case "datetime":
            return new DateFieldValue(getDateValue(), true);
        case "fee-earner":
            return new FeeEarnerFieldValue(getFeeEarnerValue());
        case "esignature": {
            const v = getEsignatureValue();
            return new EsignatureFieldValue(v.pointGroups, v.canvasHeight, v.canvasWidth);
        }
        default:
            assertNever(questionSchema);
    }

    function getStringValue() {
        if (persistedFieldState) {
            if (typeof persistedFieldState.value === "string") {
                return persistedFieldState.value;
            }
        } else {
            if (typeof seedFormValue === "string") {
                return seedFormValue;
            }
        }

        return "";
    }

    function getBooleanValue() {
        if (persistedFieldState) {
            if (typeof persistedFieldState.value === "boolean") {
                return persistedFieldState.value;
            }
        } else {
            if (typeof seedFormValue === "boolean") {
                return seedFormValue;
            } else if (
                typeof seedFormValue === "string" &&
                (seedFormValue.toLowerCase() === "yes" || seedFormValue.toLowerCase() === "no")
            ) {
                return seedFormValue.toLowerCase() === "yes";
            }
        }
        return null;
    }

    function getRadioListValue(questionSchema: RadioListQuestionSchema): [string, boolean] {
        if (persistedFieldState) {
            if (
                typeof persistedFieldState.value === "object" &&
                persistedFieldState.value != null &&
                "radioValue" in persistedFieldState.value &&
                "radioIsOther" in persistedFieldState.value
            ) {
                const { radioValue: value, radioIsOther: isOther } = persistedFieldState.value;

                if (isOther && questionSchema.hasOtherOption) {
                    return [value, true];
                }

                if (isOther && !questionSchema.hasOtherOption) {
                    return [null, false];
                }

                if (hasOption(questionSchema, value)) {
                    return [value, isOther];
                }
            }
        } else {
            if (typeof seedFormValue === "string" && hasOption(questionSchema, seedFormValue)) {
                return [seedFormValue, false];
            }
        }
        return [null, false];
    }

    function getFilesValue() {
        if (!Array.isArray(persistedFieldState?.value)) return [];

        return persistedFieldState.value.flatMap(x => (x instanceof File ? [x] : []));
    }

    function getDropdownListValue(questionSchema: DropdownListQuestionSchema): [string, boolean] {
        if (persistedFieldState) {
            if (
                persistedFieldState.value &&
                typeof persistedFieldState.value === "object" &&
                "type" in persistedFieldState.value &&
                persistedFieldState.value.type === "ddl"
            ) {
                const { value, isOther } = persistedFieldState.value;

                if (isOther && questionSchema.hasOtherOption) {
                    return [value, true];
                }

                if (isOther && !questionSchema.hasOtherOption) {
                    return [null, false];
                }

                if (hasOption(questionSchema, value)) {
                    return [value, isOther];
                }
            }
        } else {
            if (typeof seedFormValue === "string" && hasOption(questionSchema, seedFormValue)) {
                return [seedFormValue, false];
            }
        }
        return [null, false];
    }

    function getMultiselectListValue(questionSchema: MultiselectQuestionSchema): [string[], string] {
        let values: string[] = [];
        let otherValue: string = "";

        if (persistedFieldState) {
            if (
                persistedFieldState.value &&
                typeof persistedFieldState.value === "object" &&
                "type" in persistedFieldState.value &&
                persistedFieldState.value.type === "multiselect"
            ) {
                values = [...persistedFieldState.value.values];
                otherValue = questionSchema.hasOtherOption ? persistedFieldState.value.otherValue : "";
            }
        } else {
            if (isStringArray(seedFormValue)) {
                values = seedFormValue;
            } else if (typeof seedFormValue === "string") {
                values = seedFormValue.split(",").map(s => s.trim());
            }
        }

        values = values.filter(x => questionSchema.options.some(o => o.value === x));
        return [values, otherValue];
    }

    function getDateValue() {
        if (persistedFieldState) {
            if (isValid(persistedFieldState.value)) {
                return new Date(persistedFieldState.value);
            }
        } else {
            if (isValid(seedFormValue)) {
                return new Date(seedFormValue);
            }
        }
        return null;

        function isValid(value: unknown): value is string {
            return (
                typeof value === "string" &&
                value &&
                (dateTimeFn.isValidIsoDateTimeStringOrNull(value) || dateTimeFn.isDateString(value))
            );
        }
    }

    function getFeeEarnerValue() {
        if (persistedFieldState) {
            if (typeof persistedFieldState.value === "string" && feeEarnerExists(persistedFieldState.value)) {
                return persistedFieldState.value;
            }
        } else {
            if (typeof seedFormValue === "string" && feeEarnerExists(seedFormValue)) {
                return seedFormValue;
            }
        }
        return null;
    }

    function getEsignatureValue() {
        if (
            persistedFieldState &&
            persistedFieldState.value &&
            typeof persistedFieldState.value === "object" &&
            "type" in persistedFieldState.value &&
            persistedFieldState.value.type === "point-groups"
        ) {
            return persistedFieldState.value;
        }

        return {
            pointGroups: [] as PointGroup[],
            canvasHeight: null as number,
            canvasWidth: null as number
        };
    }

    function hasOption(questionSchema: RadioListQuestionSchema | DropdownListQuestionSchema, value: string) {
        return questionSchema.options.some(o => o.value === value);
    }

    function feeEarnerExists(feeEarnerGuid: string) {
        return teams?.flatMap(t => t.feeEarners).some(f => f.guid === feeEarnerGuid);
    }
};
