import { AddressQuestionSchema, QuestionSchema, TextQuestionSchema } from "@forms/types";

export const extractDataMarkersFromQuestion = (question: QuestionSchema) => {
    switch (question.type) {
        case "address":
            return [
                question.dataMarkerAddress1,
                question.dataMarkerAddress2,
                question.dataMarkerAddress3,
                question.dataMarkerAddress4,
                question.dataMarkerPostcode
            ];
        default:
            return [question.dataMarker];
    }
};

export const convertAddressQuestionToTextQuestions = (
    addressQuestionSchema: AddressQuestionSchema
): TextQuestionSchema[] => {
    return [
        {
            type: "text",
            dataMarker: addressQuestionSchema.dataMarkerAddress1,
            label: addressQuestionSchema.labelAddress1,
            required: addressQuestionSchema.required
        },
        {
            type: "text",
            dataMarker: addressQuestionSchema.dataMarkerAddress2,
            label: addressQuestionSchema.labelAddress2,
            required: false
        },
        {
            type: "text",
            dataMarker: addressQuestionSchema.dataMarkerAddress3,
            label: addressQuestionSchema.labelAddress3,
            required: false
        },
        {
            type: "text",
            dataMarker: addressQuestionSchema.dataMarkerAddress4,
            label: addressQuestionSchema.labelAddress4,
            required: false
        },
        {
            type: "text",
            dataMarker: addressQuestionSchema.dataMarkerPostcode,
            label: addressQuestionSchema.labelPostcode,
            required: false
        }
    ];
};
