import Icon from "@forms/styles/Icon";
import React from "react";

interface Props {
    message: string;
    onRestart: () => void;
}

export default function FormSubmissionError(props: Props) {
    return (
        <div className="itf-body">
            <div className="itf-error-message">{getErrorMessage()}</div>
            <button type="button" className="itf-button itf-button-retry" onClick={props.onRestart}>
                <Icon icon="faRotateLeft" />
                <span>Retry</span>
            </button>
        </div>
    );

    function getErrorMessage() {
        return `Form submission failed. ${props.message ?? ""}`;
    }
}
