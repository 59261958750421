import React, { useContext, useState } from "react";
import { FormModel, FormState, FormsAppConfig, TeamServerModel } from "@forms/types";
import { createFormState } from "@forms/state/formState";
import Loader from "../Loader";
import { useEffectOnFirstRender } from "@common";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import { wireUpConditions } from "@forms/helpers/conditionHelpers";
import Form from "./Form";
import { tryParseFormSchema } from "@forms/helpers/utils";
import FormsAppConfigContext from "@forms/state/FormsAppConfigContext";
import TeamsContext from "@forms/state/TeamsContext";

interface Props {
    formGuid: string;
    isDebug: boolean;
    scrollToFormTop: () => void;
    showPbl: boolean;
}

export default function Index(props: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const [form, setForm] = useState<FormModel>(null);
    const [initialFormState, setInitialFormState] = useState<FormState>(null);
    const [teams, setTeams] = useState<TeamServerModel[]>(null);

    const { config } = useContext(FormsAppConfigContext);

    useEffectOnFirstRender(() => {
        fetchAndPrepareData(props.formGuid, config)
            .then(result => {
                if (!result) {
                    return;
                }

                if ("errorMessage" in result) {
                    setErrorMessage(result.errorMessage);
                    return;
                }

                setForm(result.form);
                setInitialFormState(result.formState);
                setTeams(result.teams);
            })
            .finally(() => setIsLoading(false));
    });

    if (isLoading) return <Loader />;

    if (errorMessage) {
        if (errorMessage.includes("log in or register")) {
            window.location.href = window.location.origin + "?returnurl=" + window.location.href;
        }
        return <div className="itf-error-message">{errorMessage}</div>;
    }

    if (!form?.schema?.steps?.length) {
        return <div className="itf-error-message">There are no steps configured in this form</div>;
    }

    return (
        <TeamsContext.Provider value={{ teams }}>
            <Form
                form={form}
                initialFormState={initialFormState}
                isDebug={props.isDebug}
                scrollToFormTop={props.scrollToFormTop}
                showPbl={props.showPbl}
            />
        </TeamsContext.Provider>
    );
}

async function fetchAndPrepareData(formGuid: string, config: FormsAppConfig) {
    const form = await fetchForm(formGuid, config);

    if (form == null) {
        return null;
    }

    if ("errorMessage" in form) {
        return form;
    }

    form.schema.steps = wireUpConditions(form.schema.steps);

    const [formState, teams] = await createFormState(form, config.matterGuid, config.previousCompletionGuid);

    return {
        form,
        formState,
        teams
    };
}

async function fetchForm(formGuid: string, config: FormsAppConfig): Promise<FormModel | { errorMessage: string }> {
    const resp = config.matterGuid
        ? await apiEmbeddedInTouchForms.EmbeddedInTouchForms.GetFormForMatterUpdate(formGuid, config.matterGuid)
        : await apiEmbeddedInTouchForms.EmbeddedInTouchForms.GetFormForMatterCreation(formGuid);

    if (!resp.success) {
        switch (resp.statusCode) {
            case 403:
                return { errorMessage: resp.message };
            case 404:
                return { errorMessage: resp.message };
        }
        return null;
    }

    const formSchema = tryParseFormSchema(resp.data.json);
    if (!formSchema) {
        return null;
    }

    return {
        name: resp.data.name,
        guid: resp.data.guid,
        primaryColor: config.previewPrimaryColor || resp.data.primaryColor || "#1ab394",
        secondaryColor: config.previewSecondaryColor || resp.data.secondaryColor || "#1d2f42",
        tertiaryColor: config.previewTertiaryColor || resp.data.tertiaryColor || "#f4aa0b",
        schema: formSchema,
        currencySymbol: resp.data.currencySymbol
    };
}
