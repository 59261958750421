import SafeHtml from "@forms/components/SafeHtml";
import React from "react";

interface NoteProps {
    note: string;
    className?: string;
}

export default function Note(props: NoteProps) {
    return props.note ? (
        <div className={`itf-note ${props.className}`}>
            <SafeHtml html={props.note} />
        </div>
    ) : null;
}
