export default class MultiselectFieldValue {
    public readonly selectedValues: readonly string[];
    public readonly otherValue: string;

    constructor(selectedValues: readonly string[], otherValue: string) {
        this.selectedValues = selectedValues ?? [];
        this.otherValue = otherValue ?? "";
    }

    hasEntry(entry: string) {
        return this.selectedValues.includes(entry);
    }

    toggleEntry(entry: string) {
        if (this.hasEntry(entry)) {
            return new MultiselectFieldValue(
                this.selectedValues.filter(v => v !== entry),
                this.otherValue
            );
        } else {
            return new MultiselectFieldValue([...this.selectedValues, entry], this.otherValue);
        }
    }

    withOtherValue(otherValue: string) {
        return new MultiselectFieldValue(this.selectedValues, otherValue);
    }

    toSerializable() {
        return {
            type: "multiselect" as const,
            values: this.selectedValues,
            otherValue: this.otherValue
        };
    }

    exists(): boolean {
        return this.selectedValues.length > 0 || !!this.otherValue;
    }

    toServerStringValue(): string {
        return [...this.selectedValues, this.otherValue].filter(v => v).join(", ");
    }
}
