import { stringFn, dateTimeFn } from "@common";

const isFirstTimeError = (message: string, filename: string): boolean => {
    try {
        const version = dateTimeFn.asDateString(new Date().toISOString());
        const key = `${version}:${message.substring(0, 30)}:${filename.substring(0, 30)}`;
        const previousErrorEvent = window.localStorage.getItem(key);
        const now = new Date().getTime();

        if (!previousErrorEvent) {
            //set cache
            window.localStorage.setItem(key, now.toString());
            return true; //first time error
        }

        const previousDate = parseInt(previousErrorEvent);
        //force wait at least 10 seconds before counting
        const later = previousDate + 10000;
        if (later < now) {
            //clear cache
            window.localStorage.removeItem(key);
            return false; //not a first time error
        }
        //it's a first time error, or has occurred
        //quickly after the first time
        return true;
    } catch (ex) {
        console.error(`Failed to understand if error is first time: ${message}:${filename}`, ex);
        return true; //play it safe by default to first
    }
};

const record = (serverUrl: string, appName: string, event: ErrorEvent | string) => {
    const message = typeof event === "string" ? event : event.message;
    const filename = typeof event === "string" ? "no file" : event.filename || "no file";
    const lineno = typeof event === "string" ? null : event.lineno;
    const colno = typeof event === "string" ? null : event.colno;
    const error: Error = typeof event === "string" ? null : event.error;

    try {
        if (event && isFirstTimeError(message, filename)) {
            return;
        }

        const safePathName = stringFn.replaceAll(window.location.pathname, ".", "-");
        navigator.sendBeacon(`${serverUrl}/api/v2/infrastructure/errors/${appName}${safePathName}`, {
            url: window.location.href,
            errorMessage: message ?? "",
            fileName: filename ?? "",
            lineNumber: lineno ?? 0,
            colNo: colno ?? 0,
            stackTrace: (error && error.stack) || "(not set)"
        } as any);
    } catch {
        console.error("Error handling has failed");
    }
};

const startTracking = (serverUrl: string, appName: string) => {
    /**
     * window.__e should be a global error event listener, added as the very first <script> in the head of the page.
     */
    const existingErrorsObject = (window as any).__e;
    const existingErrorEvents = (existingErrorsObject && existingErrorsObject.q) || [];

    // Replay any stored load error events.
    for (const event of existingErrorEvents) {
        record(serverUrl, appName, event);
    }

    // Add a new listener to track event immediately.
    window.addEventListener("error", (event: ErrorEvent) => {
        record(serverUrl, appName, event);
    });
};

export default {
    startTracking
};
