import React, { MouseEvent, PropsWithChildren } from "react";

/**
 * Force anchor tags to reload the parent rather than change the href of the iFrame
 *
 * Once the bourg projects frontend code has been removed, this component will also be deleted.
 */
export function ParentAnchor(
    props: PropsWithChildren<any> & { href: string; style?: React.CSSProperties; className?: string }
) {
    function onClick(e: MouseEvent<HTMLAnchorElement>) {
        const isCtryKeyPressed = !!(e as any).ctrlKey;
        const isMiddleButtonPressed = e.button === 1;

        if (isCtryKeyPressed || isMiddleButtonPressed) return;

        e.preventDefault();

        //reload the parent window instead of the child
        window.parent.location.href = e.currentTarget.href;
    }

    return (
        <a onClick={onClick} href={props.href} style={props.style} className={props.className}>
            {props.children}
        </a>
    );
}
