import React from "react";

const minutesArr = [...Array(60).keys()];

interface MinutePickerProps {
    value: number;
    disabled?: boolean;
    onChange: (value: number) => void;
    minutesIncrement?: 1 | 5 | 10 | 15;
    hasEmptyOption: boolean;
    className?: string;
    onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

export default function MinutePicker(props: MinutePickerProps) {
    const minutesRange = minutesArr.filter(n => !(n % props.minutesIncrement));

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "") {
            props.onChange(null);
        } else {
            props.onChange(parseInt(e.target.value));
        }
    };

    return (
        <select
            className={"itf-select itf-minute-select " + (props.className ?? "")}
            value={props.value === null ? "" : props.value}
            disabled={props.disabled}
            onChange={handleChange}
            onBlur={props.onBlur}>
            {props.hasEmptyOption && <option value="">Min</option>}
            {minutesRange.map(m => {
                return (
                    <option key={m} value={m}>
                        {m.toString().length < 2 ? `0${m}` : m.toString()}
                    </option>
                );
            })}
        </select>
    );
}
