export default class FeeEarnerFieldValue {
    public readonly selectedValue: string;

    constructor(selectedValue: string) {
        this.selectedValue = selectedValue ?? null;
    }

    toSerializable() {
        return this.selectedValue;
    }

    exists(): boolean {
        return !!this.selectedValue;
    }

    toServerStringValue(): string {
        return this.selectedValue;
    }
}
