// NumberInput and CurrencyInput components should bind to values of type number instead of string
// to make them strongly typed and easier to deal with.
// But the currency input should allow users entering commas, for example, which makes it impossible to bind it straight to a number
// This is why the below classes exist, they hold both:
// the raw value - a string which the user enters with commas and all
// and the parsed value - a number to be used everywhere else

import { stringFn } from "@common";

export abstract class BaseNumberInputValue {
    constructor(public readonly rawValue: string, public readonly parsedValue: number) {}

    toSerializable() {
        return this.rawValue;
    }

    exists(): boolean {
        return this.parsedValue != null;
    }

    toServerStringValue(): string {
        return this.rawValue;
    }
}

export class NumberInputValue extends BaseNumberInputValue {
    constructor(rawValue: string) {
        if (rawValue) {
            super(rawValue, parseFloat(rawValue));
        } else {
            super("", null);
        }
    }
}

export class CurrencyInputValue extends BaseNumberInputValue {
    constructor(rawValue: string) {
        if (rawValue) {
            super(rawValue, parseFloat(stringFn.replaceAll(rawValue, ",", "")));
        } else {
            super("", null);
        }
    }
}
