import { LoadingIcon } from "@common";
import React from "react";

export default function Loader(props: { text?: string }) {
    const text = props.text || "Loading";

    return (
        <div className="itf-loader">
            <LoadingIcon size={38} />
            <span>{text}...</span>
        </div>
    );
}
