import React from "react";
import modal from "./modal";
import Icon from "@forms/styles/Icon";

export default function alertAsync(message: string) {
    return modal.create(({ onConfirm }) => <Alert message={message} onConfirm={onConfirm} />);
}

interface Props {
    message: string;
    onConfirm: () => void;
}

function Alert(props: Props) {
    return (
        <>
            <div className="itf-modal-header">
                <h2>{props.message}</h2>
            </div>
            <div className="itf-modal-footer">
                <button type="button" className="itf-button" onClick={props.onConfirm}>
                    <Icon icon="faCheck" />
                    <span>OK</span>
                </button>
            </div>
        </>
    );
}
