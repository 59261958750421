import { useEffect, useRef } from "react";
import { useEffectOnFirstRender } from "@common";

/**
 * Hook that listens clicks outside of reference element
 */
export default function <T extends HTMLElement>(callback: (e: MouseEvent) => void) {
    const callbackRef = useRef<(e: MouseEvent) => void>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<T>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffectOnFirstRender(() => {
        //determine which events to use
        let eventName = "mousedown";

        if ("ontouchstart" in document.documentElement) {
            eventName = "touchstart";
        }

        document.addEventListener(eventName, handleClick);
        return () => document.removeEventListener(eventName, handleClick);

        function handleClick(e: any) {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
                callbackRef.current(e);
            }
        }
    }); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
}
