import { shortId } from "@common";
import useFieldState from "@forms/hooks/useFieldState";
import TeamsContext from "@forms/state/TeamsContext";
import { FeeEarnerFieldValue, FeeEarnerQuestionSchema } from "@forms/types";
import React, { useContext, useState } from "react";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function FeeEarnerField(props: FieldProps<FeeEarnerQuestionSchema>) {
    const [id] = useState(shortId());

    const fs = useFieldState<FeeEarnerFieldValue>(props);

    const validationResult = fs.getValidationResult();

    const { teams } = useContext(TeamsContext);

    return (
        <>
            <FieldHeader {...props.schema} id={id} />
            <select
                className={"itf-select " + (validationResult.isInvalid ? "is-invalid" : "")}
                value={fs.value.selectedValue ?? ""}
                disabled={props.disabled}
                onChange={e => fs.setValue(new FeeEarnerFieldValue(e.target.selectedOptions[0].value))}
                onBlur={() => fs.setIsTouched()}>
                <option value="" disabled>
                    Select fee earner
                </option>
                {teams.map((t, i) => (
                    <optgroup key={i} label={t.name}>
                        {t.feeEarners.map(f => (
                            <option key={f.guid} value={f.guid}>
                                {f.fullname}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            <ValidationMessage {...validationResult} />
        </>
    );
}
