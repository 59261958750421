import { shortId } from "@common";
import React, { useState } from "react";

interface CheckboxProps {
    label: string;
    value: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
    onBlur?: () => void;
    className?: string;
    required?: boolean;
}

export default function Checkbox(props: CheckboxProps) {
    const [id] = useState("checkbox-" + shortId());

    return (
        <div>
            <input
                id={id}
                type="checkbox"
                className={"itf-checkbox-input " + (props.className ?? "")}
                checked={props.value}
                disabled={props.disabled}
                onChange={e => props.onChange(e.target.checked)}
                onBlur={props.onBlur}
            />
            <label className="itf-label" htmlFor={id}>
                {props.label} {props.required && <span className="itf-required">*</span>}
            </label>
        </div>
    );
}
