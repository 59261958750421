﻿/**
* InTouch API TypeScript Client
* Swagger Version 2.0
* Generated on API End Point localhost:44312
*/
import { http } from "@common";
module apiEmbeddedInTouchForms {
    export module InTouchFormAddressLookup {

        export const Find_Url = (): string => {
            return `/api/v2/embedded-intouch-forms/address-lookup`;
        };

        export const Find = (data: { value: string}):
            Promise<Models.ApiResponseWithData<Models.AddressLookup_AddressModel[]>> => {
            return http.get({
                url: Find_Url(),
                data: data
            });
        };

        export const GetFullInfo_Url = (): string => {
            return `/api/v2/embedded-intouch-forms/address-lookup/full-info`;
        };

        export const GetFullInfo = (data: { value: string}):
            Promise<Models.ApiResponseWithData<Models.AddressLookup_AddressModel>> => {
            return http.get({
                url: GetFullInfo_Url(),
                data: data
            });
        };

        export const FindSuggestions_Url = (): string => {
            return `/api/v2/embedded-intouch-forms/address-lookup/suggestions`;
        };

        export const FindSuggestions = (data: { value: string}):
            Promise<Models.ApiResponseWithData<Models.General_PairModel_String[]>> => {
            return http.get({
                url: FindSuggestions_Url(),
                data: data
            });
        };
    };
    export module EmbeddedInTouchForms {

        export const CompileHtmlBlocks_Url = (): string => {
            return `/api/v2/embedded-intouch-forms/compile-html-blocks`;
        };

        export const CompileHtmlBlocks = (data: Models.InTouchForms_InTouchFormHtmlBlockCompilationRequestModel):
            Promise<Models.ApiResponseWithData<string[]>> => {
            return http.post({
                url: CompileHtmlBlocks_Url(),
                data: data
            });
        };

        export const GetFormForMatterCreation_Url = (formGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/forms/${formGuid}`;
        };

        export const GetFormForMatterCreation = (formGuid: string):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_EmbeddedInTouchFormViewModel>> => {
            return http.get({
                url: GetFormForMatterCreation_Url(formGuid)
            });
        };

        export const GetFormForMatterUpdate_Url = (formGuid: string, matterGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/forms/${formGuid}/${matterGuid}`;
        };

        export const GetFormForMatterUpdate = (formGuid: string, matterGuid: string):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_EmbeddedInTouchFormViewModel>> => {
            return http.get({
                url: GetFormForMatterUpdate_Url(formGuid, matterGuid)
            });
        };

        export const GetMatterAnswers_Url = (formGuid: string, matterGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/forms/${formGuid}/matter/${matterGuid}/answers`;
        };

        export const GetMatterAnswers = (formGuid: string, matterGuid: string):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_InTouchFormQuestionAnswerModel[]>> => {
            return http.get({
                url: GetMatterAnswers_Url(formGuid, matterGuid)
            });
        };

        export const GetPreviousCompletion_Url = (matterGuid: string, completionGuid: string, formGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/forms/${formGuid}/matter/${matterGuid}/previous/${completionGuid}`;
        };

        export const GetPreviousCompletion = (matterGuid: string, completionGuid: string, formGuid: string):
            Promise<Models.ApiResponseWithData<Models.General_SingleValueModel_String>> => {
            return http.get({
                url: GetPreviousCompletion_Url(matterGuid, completionGuid, formGuid)
            });
        };

        export const GetTeams_Url = (formGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/forms/${formGuid}/teams`;
        };

        export const GetTeams = (formGuid: string):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_InTouchFormTeamModel[]>> => {
            return http.post({
                url: GetTeams_Url(formGuid)
            });
        };

        export const GetQuote_Url = (formGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/quote/${formGuid}`;
        };

        export const GetQuote = (formGuid: string, data: Models.InTouchForms_Quote_GetQuoteRequestModel):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_Quote_QuoteModel>> => {
            return http.post({
                url: GetQuote_Url(formGuid),
                data: data
            });
        };
    };
    export module InTouchFormCompletion {

        export const CompleteByUpdatingMatter_Url = (formGuid: string, matterGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/completion/${formGuid}/matter/${matterGuid}`;
        };

        export const CompleteByUpdatingMatter = (formGuid: string, matterGuid: string, data: Models.InTouchForms_Completion_InTouchFormCompletionRequestModel):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_Completion_InTouchFormCompletionResultModel>> => {
            return http.post({
                url: CompleteByUpdatingMatter_Url(formGuid, matterGuid),
                data: data
            });
        };

        export const CompleteByCreatingMatter_Url = (formGuid: string): string => {
            return `/api/v2/embedded-intouch-forms/completion/${formGuid}/matter/create`;
        };

        export const CompleteByCreatingMatter = (formGuid: string, data: Models.InTouchForms_Completion_InTouchFormCompletionRequestModel):
            Promise<Models.ApiResponseWithData<Models.InTouchForms_Completion_InTouchFormCompletionResultModel>> => {
            return http.post({
                url: CompleteByCreatingMatter_Url(formGuid),
                data: data
            });
        };

        export const UploadFiles_Url = (): string => {
            return `/api/v2/embedded-intouch-forms/completion/upload-files`;
        };

        export const UploadFiles = ():
            Promise<Models.ApiResponseWithData<Models.InTouchForms_Completion_InTouchUploadedFile[]>> => {
            return http.post({
                url: UploadFiles_Url()
            });
        };
    };
    export module Models {
        export interface ApiResponse {
            errors?: string[];
            message?: string;
            statusCode?: number;
            success?: boolean;
        }
        export interface ApiResponseWithData<T> extends ApiResponse {
            data?: T;
        }
        export interface Dictionary<T> {
            [key: string]: T
        }
        export interface AddressLookup_AddressModel {
            address1?: string;
            address2?: string;
            city?: string;
            county?: string;
            fullAddress?: string;
            postcode?: string;
        }
        export interface General_PairModel_String {
            caption?: string;
            id?: string;
        }
        export interface General_SingleRequiredValueModel_String {
            value: string;
        }
        export interface General_SingleValueModel_String {
            value?: string;
        }
        export interface InTouchForms_Completion_InTouchFormCompletionRequestModel {
            agreesToTermsOfService: boolean;
            cnt: number;
            data: Models.Dictionary<string>;
            files: Models.InTouchForms_Completion_InTouchUploadedFile[];
            previousCompletionGuid?: string;
            signatureBase64?: string;
            timeStamp: string;
            visibleFeeDataMarkers: string[];
        }
        export interface InTouchForms_Completion_InTouchFormCompletionResultModel {
            chainedFormLink?: string;
            matterGuid?: string;
            matterLink?: string;
        }
        export interface InTouchForms_Completion_InTouchUploadedFile {
            contentType?: string;
            dataMarker?: string;
            guid?: string;
            name?: string;
        }
        export interface InTouchForms_EmbeddedInTouchFormViewModel {
            currencySymbol?: string;
            guid?: string;
            json?: string;
            name?: string;
            primaryColor?: string;
            secondaryColor?: string;
            tertiaryColor?: string;
        }
        export interface InTouchForms_InTouchFormFeeEarnerModel {
            fullname?: string;
            guid?: string;
        }
        export interface InTouchForms_InTouchFormHtmlBlockCompilationRequestModel {
            data: Models.Dictionary<string>;
            htmlTemplates: string[];
            matterGuid?: string;
        }
        export interface InTouchForms_InTouchFormQuestionAnswerModel {
            answer?: string;
            dataMarker?: string;
        }
        export interface InTouchForms_InTouchFormTeamModel {
            feeEarners?: Models.InTouchForms_InTouchFormFeeEarnerModel[];
            name?: string;
        }
        export interface InTouchForms_Quote_GetQuoteRequestModel {
            data: Models.Dictionary<string>;
            visibleFeeDataMarkers: string[];
        }
        export interface InTouchForms_Quote_QuoteLineItemModel {
            charge?: number;
            convertToReceivableAccountGuid?: string;
            convertToReceivableContactGuid?: string;
            dataMarker?: string;
            name?: string;
            notes?: string;
            tax?: number;
            taxPercentage?: number;
        }
        export interface InTouchForms_Quote_QuoteModel {
            grandTotal?: number;
            lineItems?: Models.InTouchForms_Quote_QuoteLineItemModel[];
            totalCharge?: number;
            totalTax?: number;
        }
    };
}
export default apiEmbeddedInTouchForms