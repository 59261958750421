import { useEffectOnFirstRender, useEffectOnUpdate } from "@common";
import React, { useRef } from "react";
import SignaturePad, { PointGroup } from "signature_pad";
import "./SignatureInput.scss";
import Icon from "@forms/styles/Icon";

interface Props {
    value: readonly PointGroup[];
    disabled?: boolean;
    onUpdate: (value: PointGroup[], canvasHeight: number, canvasWidth: number) => void;
}

export default function SignatureInput(props: Props) {
    const canvasRef = useRef<HTMLCanvasElement>();
    const padRef = useRef<SignaturePad>();

    const emitUpdate = () => {
        const data = padRef.current.toData();
        props.onUpdate(data, canvasRef.current.height, canvasRef.current.width);
    };

    const clearPad = () => {
        if (padRef.current) {
            padRef.current.clear();
            emitUpdate();
        }
    };

    const resizeCanvas = () => {
        if (canvasRef.current.width !== canvasRef.current.parentElement.offsetWidth) {
            canvasRef.current.width = canvasRef.current.parentElement.offsetWidth;
            clearPad();
        }
    };

    useEffectOnFirstRender(() => {
        resizeCanvas();
        window.addEventListener("resize", resizeCanvas);

        padRef.current = new SignaturePad(canvasRef.current);

        if (props.value) {
            padRef.current.fromData([...props.value]);
        }

        padRef.current.addEventListener("endStroke", () => {
            emitUpdate();
        });

        if (props.disabled) {
            padRef.current.off();
        }

        return () => {
            window.removeEventListener("resize", resizeCanvas);
        };
    });

    useEffectOnUpdate(() => {
        if (!padRef.current) {
            return;
        }

        if (props.value === padRef.current.toData()) {
            return;
        }

        if (props.value) {
            padRef.current.fromData([...props.value]);
        } else {
            padRef.current.clear();
        }
    }, [props.value]);

    return (
        <div className="signature-container">
            <canvas
                ref={canvasRef}
                height="200"
                style={{ backgroundColor: props.disabled ? "#e9ecef" : "white" }}></canvas>
            <button className="itf-button" disabled={props.disabled} onClick={clearPad}>
                <Icon icon="faXmark" />
            </button>
        </div>
    );
}
