import { shortId, preventDefault } from "@common";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import { AddressServerModel } from "@forms/types";
import React, { useState } from "react";
import LoadingIcon from "@common/components/LoadingIcon";

const postcodeRegex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
const isPostcodeValid = (value: string) => postcodeRegex.test(value);

interface Props {
    addressFieldsVisible: boolean;
    disabled: boolean;
    onChange: (address: AddressServerModel) => void;
    onBlur: () => void;
    onEnterManually: () => void;
}

export default function AddressLookupUK(props: Props) {
    const [postcode, setPostcode] = useState("");
    const [loading, setIsLoading] = useState(false);
    const [addresses, setAddresses] = useState<(AddressServerModel & { id: string })[]>([]);
    const [selectedAddressId, setSelectedAddressId] = useState("");

    const onPostcodeChange = async (value: string) => {
        setPostcode(value);

        if (!isPostcodeValid(value)) {
            return;
        }

        setIsLoading(true);
        try {
            const resp = await apiEmbeddedInTouchForms.InTouchFormAddressLookup.Find({ value });
            if (resp.success) {
                setAddresses(
                    resp.data.map(a => ({
                        id: shortId(),
                        ...a,
                        postcode: a.postcode ?? value
                    }))
                );
            } else {
                setAddresses([]);
            }
            setSelectedAddressId("");
        } catch (e) {
            setAddresses([]);
            setSelectedAddressId("");
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    const onAddressChange = (id: string) => {
        setSelectedAddressId(id);
        const address = addresses.find(a => a.id === id);
        props.onChange(address);
    };

    const validPostcode = isPostcodeValid(postcode);

    return (
        <div className="itf-address-lookup">
            <div>
                <input
                    type="text"
                    className="itf-text-input"
                    value={postcode}
                    onChange={e => onPostcodeChange(e.target.value)}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    placeholder="Enter a valid Postcode to lookup an address"
                />
                {postcode && !validPostcode && <div className="itf-invalid-answer">Enter a valid Postcode</div>}
            </div>
            {loading ? (
                <div className="itf-address-loader">
                    <LoadingIcon size={25} />
                </div>
            ) : (
                validPostcode && (
                    <>
                        <div className="itf-address-select">
                            <label className="itf-label">{addresses.length} matching addresses found</label>
                            <select
                                className="itf-select"
                                disabled={addresses.length === 0}
                                value={selectedAddressId}
                                onChange={e => onAddressChange(e.target.value)}>
                                <option disabled value="">
                                    Select an address
                                </option>
                                {addresses.map(a => (
                                    <option key={a.id} value={a.id}>
                                        {a.fullAddress}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                )
            )}
            {!props.addressFieldsVisible && (
                <a className="itf-address-button" href="#" onClick={preventDefault(props.onEnterManually)}>
                    Enter Address
                </a>
            )}
        </div>
    );
}
