import { FormState, FormSchema, FileFieldValue, EsignatureFieldValue } from "@forms/types";
import { isDataMarker, Dictionary } from "@common";
import { areAllConditionsSatisfied } from "./conditionHelpers";
import formSchemaFn from "./formSchemaFn";

const toCompletionData = (
    formState: FormState,
    formSchema: FormSchema
): {
    data: Dictionary<string>;
    files: readonly File[];
    signatureBase64: string;
} => {
    const data: Dictionary<string> = {};
    const files: File[] = [];
    let signatureBase64 = "";

    for (const [key, { value }] of Object.entries(formState)) {
        if (value == null) continue;

        const questionBlock = formSchemaFn.findQuestionBlockByDataMarker(formSchema, key);

        if (!areAllConditionsSatisfied(questionBlock.conditions ?? [], formState)) {
            continue;
        }

        if (value instanceof FileFieldValue) {
            const copies = value.files.map(f => new File([f], key + f.name, { type: f.type }));
            files.push(...copies);
            continue;
        }

        if (value instanceof EsignatureFieldValue) {
            signatureBase64 = value.toBase64();
            continue;
        }

        if (!isDataMarker(key)) continue;

        data[key] = value.toServerStringValue();
    }

    return {
        data,
        files,
        signatureBase64
    };
};

export default {
    toCompletionData
};
