import { shortId } from "@common";
import React, { useState } from "react";
import { TextareaQuestionSchema, TextFieldValue } from "src/forms/types";
import useFieldState from "../../hooks/useFieldState";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function TextareaField(props: FieldProps<TextareaQuestionSchema>) {
    const [id] = useState("textarea-" + shortId());

    const fs = useFieldState<TextFieldValue>(props);

    const validationResult = fs.getValidationResult();

    return (
        <>
            <FieldHeader {...props.schema} id={id} />
            <textarea
                id={id}
                className={"itf-text-input " + (validationResult.isInvalid ? "is-invalid" : "")}
                rows={3}
                value={fs.value.actualValue}
                disabled={props.disabled}
                onChange={e => fs.setValue(fs.value.update(e.target.value))}
                onBlur={() => fs.setIsTouched()}
            />
            <ValidationMessage {...validationResult} />
        </>
    );
}
