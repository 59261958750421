import { QuestionSchema } from "@forms/types";
import React from "react";
import Note from "./Note";

type Props = QuestionSchema & { id?: string };

export default function FieldHeader(props: Props) {
    return (
        <>
            <label className="itf-label" htmlFor={props.id}>
                {props.label} {props.required && <span className="itf-required">*</span>}
            </label>
            <Note className={`note-${props.type}`} note={props.note} />
        </>
    );
}
