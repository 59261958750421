import React from "react";
import { CheckboxFieldValue, CheckboxQuestionSchema } from "../../types";
import Note from "./components/Note";
import useFieldState from "../../hooks/useFieldState";
import Checkbox from "../Checkbox";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function CheckboxField(props: FieldProps<CheckboxQuestionSchema>) {
    const fs = useFieldState<CheckboxFieldValue>(props);

    return (
        <>
            <Checkbox
                label={props.schema.label}
                value={fs.value.actualValue}
                disabled={props.disabled}
                onChange={v => {
                    fs.setIsTouched();
                    fs.setValue(new CheckboxFieldValue(v));
                }}
                onBlur={fs.setIsTouched}
                required={props.schema.required}
            />
            <Note className={`note note-${props.schema.type}`} note={props.schema.note} />
            <ValidationMessage {...fs.getValidationResult()} />
        </>
    );
}
