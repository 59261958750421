export default class YesNoFieldValue {
    public readonly actualValue?: boolean;

    constructor(actualValue?: boolean) {
        this.actualValue = actualValue;
    }

    toSerializable() {
        return this.actualValue;
    }

    exists(): boolean {
        return this.actualValue != null;
    }

    toServerStringValue(): string {
        return this.actualValue == null ? null : this.actualValue ? "yes" : "no";
    }
}
