const emptyGuid = "00000000-0000-0000-0000-000000000000";
/**
 * Create a new guid
 */
const newGuid = (): string =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
/**
 * Test is the input is a guid
 */
const isGuid = (input: string): boolean => {
    if (!input) return false;

    if (input === emptyGuid) return false;

    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return regex.test(input);
};

export default {
    emptyGuid,
    newGuid,
    isGuid
};
