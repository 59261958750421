import React, { useRef } from "react";
import Icon from "@forms/styles/Icon";

interface FileUploadButtonProps {
    multiple?: boolean;
    accept?: string;
    disabled?: boolean;
    onFileSelect: (file: FileList) => void;
    onBlur: () => void;
    children: string | JSX.Element;
}

export default function FileUploadButton(props: FileUploadButtonProps) {
    const ref = useRef<HTMLInputElement>();

    const handleClick = () => {
        if (ref.current) {
            ref.current.value = null;
            ref.current.click();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onFileSelect(e.target.files);
    };

    return (
        <>
            <button type="button" className="itf-button" onClick={handleClick} disabled={props.disabled} tabIndex={0}>
                <Icon icon="faFileAlt" />
                <span>{props.children}</span>
            </button>
            <input
                ref={ref}
                type="file"
                className="itf-file-input"
                style={{ display: "none" }}
                multiple={props.multiple}
                accept={props.accept}
                onChange={handleChange}
                onBlur={props.onBlur}
            />
        </>
    );
}
