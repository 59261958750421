import React, { useEffect } from "react";

/**
 * This custom hook has exactly the same functionality as useEffect hook,
 * but with a more readable name and the deps array abstracted into this wrapper.
 *
 * @param effect Imperative function that can return a cleanup function.
 *
 */
export default function useEffectOnFirstRender(effect: React.EffectCallback) {
    useEffect(effect, []);
}
