import { guid } from "@common";
import { FormsAppConfig } from "@forms/types";

const sanitize = (config: unknown): FormsAppConfig => {
    if (config == null || typeof config !== "object") {
        throw new Error("Configuration object is required");
    }

    return {
        guid: sanitizeGuid(config),
        container: sanitizeContainer(config),
        serverUrl: sanitizeServerUrl(config),
        submissionData:
            "submissionData" in config && typeof config.submissionData === "object" ? config.submissionData : null,
        country: sanitizeCountry(config),
        events: sanitizeEvents(config),
        functions: sanitizeFunctions(config),
        matterGuid: santiseGuidValue("matterGuid", config),
        previousCompletionGuid: santiseGuidValue("previousCompletionGuid", config),
        token: "token" in config && typeof config.token === "string" ? config.token : null,
        previewPrimaryColor:
            "previewPrimaryColor" in config && typeof config.previewPrimaryColor === "string"
                ? config.previewPrimaryColor
                : null,
        previewSecondaryColor:
            "previewSecondaryColor" in config && typeof config.previewSecondaryColor === "string"
                ? config.previewSecondaryColor
                : null,
        previewTertiaryColor:
            "previewTertiaryColor" in config && typeof config.previewTertiaryColor === "string"
                ? config.previewTertiaryColor
                : null,
        isInMatterModal:
            "isInMatterModal" in config && typeof config.isInMatterModal === "boolean" ? config.isInMatterModal : null,
        noPbl: "noPbl" in config && config.noPbl === true ? true : false
    };
};

export default {
    sanitize
};

const sanitizeGuid = (config: object): string => {
    if ("guid" in config && typeof config.guid === "string" && guid.isGuid(config.guid)) {
        return config.guid;
    }
    throw missingOrInvalidProp("guid");
};

const sanitizeContainer = (config: object): HTMLElement => {
    if ("container" in config && config.container instanceof HTMLElement) {
        return config.container;
    }
    throw missingOrInvalidProp("container");
};

const sanitizeServerUrl = (config: object): string => {
    if ("serverUrl" in config && typeof config.serverUrl === "string") {
        return config.serverUrl;
    }
    throw missingOrInvalidProp("serverUrl");
};

const sanitizeCountry = (config: object): "UK" | "AUS" => {
    if (!("country" in config) || config.country == null) {
        return "UK";
    }

    if (config.country === "UK" || config.country === "AUS") {
        return config.country;
    }

    throw missingOrInvalidProp("country");
};

const sanitizeEvents = (config: object): FormsAppConfig["events"] => {
    if (!("events" in config) || config.events == null || typeof config.events !== "object") {
        return {};
    }

    return {
        onSubmitted:
            "onSubmitted" in config.events && typeof config.events.onSubmitted === "function"
                ? wrapInTryCatch(config.events.onSubmitted as any, "onSubmitted", "event")
                : null,
        onStepChange:
            "onStepChange" in config.events && typeof config.events.onStepChange === "function"
                ? wrapInTryCatch(config.events.onStepChange as any, "onStepChange", "event")
                : null
    };
};

const sanitizeFunctions = (config: object): FormsAppConfig["functions"] => {
    if (!("functions" in config) || config.functions == null || typeof config.functions !== "object") {
        return {};
    }

    return {
        scrollToFormTop:
            "scrollToFormTop" in config.functions && typeof config.functions.scrollToFormTop === "function"
                ? wrapInTryCatch(config.functions.scrollToFormTop as any, "scrollToFormTop", "function")
                : null
    };
};

const wrapInTryCatch = <T extends unknown[]>(
    func: (...args: T) => void,
    funcName: string,
    type: "event" | "function"
) => {
    return (...args: T) => {
        try {
            func(...args);
        } catch (ex) {
            console.error(`Custom ${funcName} ${type} threw an error;`);
            console.error(ex);
        }
    };
};

function missingOrInvalidProp(name: keyof FormsAppConfig): Error {
    return new Error(`The property ${name} is missing or incorrectly configured`);
}

function santiseGuidValue(guidPropName: keyof FormsAppConfig, config: object): string {
    if (!(guidPropName in config)) {
        return null;
    }

    const value = (config as any)[guidPropName];

    if (!value || typeof value !== "string" || !guid.isGuid(value)) throw missingOrInvalidProp(guidPropName);

    return value;
}
