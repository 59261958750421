import { getClipboardAsCurrency } from "@common/clipboard";
import { handleCurrencyInputKeyboardEvent } from "@common/events/keyboard/handleCurrencyInputKeyboardEvent";
import React from "react";
import { CurrencyInputValue } from "./components.types";
import { formatMoney } from "@forms/helpers/utils";

interface CurrencyInputProps {
    id?: string;
    className?: string;
    value: CurrencyInputValue;
    disabled?: boolean;
    onChange: (value: CurrencyInputValue) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    currencySymbol: string;
}

export default function CurrencyInput(props: CurrencyInputProps) {
    const value = props.value ?? new CurrencyInputValue("");

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const result = getClipboardAsCurrency(e);

        if (result.hasValue) {
            props.onChange(new CurrencyInputValue(result.value));
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (props.value.parsedValue) {
            props.onChange(new CurrencyInputValue(formatMoney(props.value.parsedValue)));
        }

        props.onBlur?.(e);
    };

    return (
        <div className="itf-currency-input">
            <span className={props.className}>{props.currencySymbol}</span>
            <input
                type="text"
                id={props.id}
                className={"itf-text-input " + (props.className ?? "")}
                value={value.rawValue}
                disabled={props.disabled}
                onKeyDown={handleCurrencyInputKeyboardEvent}
                onChange={e => props.onChange(new CurrencyInputValue(e.target.value))}
                onPaste={handlePaste}
                onBlur={handleBlur}
            />
        </div>
    );
}
