/**
 * Log of messages that may be useful when debugging.
 */
export const logMessages: string[] = [];

export default {
    debug: function (message: string) {
        logMessages.push(message);
        console.log(`%c🪲\t${message}`, "color: #88129d; font-weight:bold");
    },
    deprecated: function (message: string) {
        console.warn(`%c\t[Deprecated]\t${message}`, "color: Gray");
    },
    error: function (message: string, ...optionalArgsToLog: any[]) {
        logMessages.push(message);
        console.log(`%c💥\t${message}`, "color: #88129d; font-weight:bold", ...optionalArgsToLog);
    },
    event: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Event]\t${message}`;
        logMessages.push(result);
        console.log(`%c\t${result}`, "color: #ff1199", ...optionalArgsToLog);
    },
    info: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Info]\t${message}`;
        logMessages.push(result);
        console.log(`%c\t${result}`, "color: DodgerBlue", ...optionalArgsToLog);
    },
    success: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Success]\t${message}`;
        logMessages.push(result);
        console.log(`%c\t${result}`, "color: Green", ...optionalArgsToLog);
    },
    warn: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Warning]\t${message}`;
        logMessages.push(result);
        console.log(`%c\t${result}`, "color: Orange", ...optionalArgsToLog);
    }
};
