import useFieldState from "@forms/hooks/useFieldState";
import { EsignatureFieldValue, EsignatureQuestionSchema } from "@forms/types";
import React from "react";
import SignatureInput from "../SignatureInput";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function EsignatureField(props: FieldProps<EsignatureQuestionSchema>) {
    const fs = useFieldState<EsignatureFieldValue>(props);

    const validationResult = fs.getValidationResult();

    return (
        <>
            <FieldHeader {...props.schema} />
            <SignatureInput
                value={fs.value.pointGroups}
                disabled={props.disabled}
                onUpdate={(value, height, width) => {
                    fs.setIsTouched();
                    fs.setValue(new EsignatureFieldValue(value, height, width));
                }}
            />
            <ValidationMessage {...validationResult} />
        </>
    );
}
