const newParams = (params: { [index: string]: any }) => {
    const noEmpties: { [index: string]: any } = {};
    for (const key in params) {
        if (params[key] !== undefined && params[key] !== null && params[key] !== "") {
            noEmpties[key] = params[key];
        }
    }

    return new URLSearchParams(noEmpties);
};

export const queryString = {
    has: (key: string, queryString: string = window.location.search): boolean => {
        const urlSearchParams = new URLSearchParams(queryString);
        return urlSearchParams.has(key);
    },
    get: (key: string, queryString: string = window.location.search): string | null => {
        const urlSearchParams = new URLSearchParams(queryString);
        return urlSearchParams.get(key) ?? null;
    },
    set: (key: string, value?: string, w: Window = window) => {
        const url = new URL(w.location.href);
        url.searchParams.set(key, value);
        w.history.replaceState(null, "", url.toString());
    },
    remove: (key: string, w: Window = window) => {
        const url = new URL(w.location.href);
        url.searchParams.delete(key);
        w.history.replaceState(null, "", url.toString());
    },
    newParams
};
