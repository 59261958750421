import React from "react";
import { YesNoFieldValue, YesNoQuestionSchema } from "src/forms/types";
import useFieldState from "../../hooks/useFieldState";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function YesNoField(props: FieldProps<YesNoQuestionSchema>) {
    const fs = useFieldState<YesNoFieldValue>(props);

    const validationResult = fs.getValidationResult();

    return (
        <>
            <FieldHeader {...props.schema} />
            {props.disabled && (
                <div className="itf-yes-no">
                    <div className="yes disabled">Yes</div>
                    <div className="null disabled">?</div>
                    <div className="no disabled">No</div>
                </div>
            )}

            {!props.disabled && (
                <>
                    <div className={"itf-yes-no" + (validationResult.isInvalid ? " is-invalid" : "")}>
                        <div
                            onClick={() => change(true)}
                            className={"yes" + (fs.value.actualValue === true ? " selected" : "")}>
                            Yes
                        </div>
                        <div
                            onClick={() => change(null)}
                            className={"null" + (fs.value.actualValue === null ? " selected" : "")}>
                            ?
                        </div>
                        <div
                            onClick={() => change(false)}
                            className={"no" + (fs.value.actualValue === false ? " selected" : "")}>
                            No
                        </div>
                    </div>

                    <ValidationMessage {...validationResult} />
                </>
            )}
        </>
    );

    function change(value?: boolean) {
        fs.setValue(new YesNoFieldValue(value));
        fs.setIsTouched();
    }
}
