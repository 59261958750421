/**
 * A native promise that acts like the JQuery deferred API
 **/
export interface Deferred<T> {
    promise: Promise<T>;
    reject: (reason: string | Error) => void;
    resolve: (value: T) => void;
}

export default function deferred<T>(): Deferred<T> {
    let resolve: (value: T) => void;
    let reject: (reason: string | Error) => void;

    const p = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });

    return {
        promise: p,
        reject,
        resolve
    };
}
