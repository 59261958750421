import React from "react";
import FieldDataMarker from "../components/FieldDataMarker";

interface Props {
    label: string;
    dataMarker: string;
    value: string;
    isInvalid: boolean;
    disabled: boolean;
    onChange: (value: string) => void;
    onTouched: () => void;
}

export default function AddressTextField(props: Props) {
    const isBuilder = props.disabled;

    return (
        <div className="itf-address-input">
            <input
                type="text"
                placeholder={props.label}
                className={"itf-text-input " + (props.isInvalid ? "is-invalid" : "")}
                value={props.value}
                disabled={props.disabled}
                onChange={e => props.onChange(e.target.value)}
                onBlur={props.onTouched}
            />
            {isBuilder && <FieldDataMarker dataMarker={props.dataMarker} />}
        </div>
    );
}
