import { getClipboardAsNumber } from "@common/clipboard";
import { handleNumberInputKeyboardEvent } from "@common/events/keyboard/handleNumberInputKeyboardEvent";
import React from "react";
import { NumberInputValue } from "./components.types";

interface NumberInputProps {
    id?: string;
    className?: string;
    value: NumberInputValue;
    disabled?: boolean;
    onChange: (value: NumberInputValue) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export default function NumberInput(props: NumberInputProps) {
    const value = props.value ?? new NumberInputValue("");

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const result = getClipboardAsNumber(e);
        if (result.hasValue) {
            props.onChange(new NumberInputValue(result.value));
        }
    };

    return (
        <input
            type="text"
            id={props.id}
            className={"itf-text-input " + (props.className ?? "")}
            value={value.rawValue}
            disabled={props.disabled}
            onKeyDown={handleNumberInputKeyboardEvent}
            onChange={e => props.onChange(new NumberInputValue(e.target.value))}
            onPaste={handlePaste}
            onBlur={props.onBlur}
        />
    );
}
