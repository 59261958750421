import React from "react";
import DomPurify from "dompurify";

interface Props {
    html: string;
}

export default function SafeHtml(props: Props) {
    // adding an event that adds target=_blank and rel=noreferrer to all anchors
    DomPurify.addHook("afterSanitizeAttributes", addTargetBlankToAnchor);

    // the USE_PROFILES directive configures the sanitizer to only allow html
    // and disallow things like svg and MathML
    const clean = DomPurify.sanitize(props.html, { USE_PROFILES: { html: true } });

    DomPurify.removeHook("afterSanitizeAttributes");
    return <div dangerouslySetInnerHTML={{ __html: clean }} />;
}

function addTargetBlankToAnchor(node: Element) {
    if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noreferrer");
    }
}
