import React, { useState } from "react";
import { shortId } from "@common";

interface RadioProps {
    name: string;
    label: string;
    checked: boolean;
    disabled?: boolean;
    onChecked: () => void;
    onBlur?: () => void;
    className?: string;
}

export default function Radio(props: RadioProps) {
    const [id] = useState("radio-" + shortId());

    return (
        <div>
            <input
                className={"itf-radio-input " + (props.className ?? "")}
                type="radio"
                id={id}
                name={props.name}
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChecked}
                onBlur={props.onBlur}
            />
            <label className="itf-label" htmlFor={id}>
                {props.label}
            </label>
        </div>
    );
}
