import { useState } from "react";

/**
 * Like useState, but also calls a callback when the state changes.
 */
export default function useStateObservable<T>(initial: T, onChange: (s: T) => void): [T, (s: T) => void] {
    const [state, setState] = useState<T>(initial);

    const newSetState = (newState: T) => {
        setState(newState);
        onChange(newState);
    };

    return [state, newSetState];
}
