import { shortId } from "@common";
import React, { useState } from "react";
import { NumberQuestionSchema } from "src/forms/types";
import { NumberInputValue } from "../components.types";
import useFieldState from "../../hooks/useFieldState";
import NumberInput from "../NumberInput";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function NumberField(props: FieldProps<NumberQuestionSchema>) {
    const [id] = useState("number-input-" + shortId());

    const fs = useFieldState<NumberInputValue>(props);

    const validationResult = fs.getValidationResult();

    return (
        <>
            <FieldHeader {...props.schema} id={id} />
            <NumberInput
                id={id}
                className={validationResult.isInvalid ? "is-invalid" : ""}
                value={fs.value}
                disabled={props.disabled}
                onChange={fs.setValue}
                onBlur={() => fs.setIsTouched()}
            />
            <ValidationMessage {...validationResult} />
        </>
    );
}
