import { shortId, useEffectOnUpdate } from "@common";
import React, { Fragment, useRef, useState } from "react";
import { RadioListFieldValue, RadioListQuestionSchema } from "src/forms/types";
import useFieldState from "../../hooks/useFieldState";
import Note from "./components/Note";
import Radio from "../Radio";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function RadioListField(props: FieldProps<RadioListQuestionSchema>) {
    const [name] = useState(props.schema.dataMarker + shortId());

    const fs = useFieldState<RadioListFieldValue>(props);

    const validationResult = fs.getValidationResult();

    return (
        <>
            <FieldHeader {...props.schema} />
            <div>
                <ValidationMessage {...validationResult} />
            </div>
            {props.schema.options.map((opt, i) => (
                <Fragment key={i}>
                    <Radio
                        name={name}
                        label={opt.label}
                        checked={!fs.value.isOtherOption && fs.value.actualValue === opt.value}
                        onChecked={() => fs.setValue(new RadioListFieldValue(opt.value, false))}
                        disabled={props.disabled}
                        className={validationResult.isInvalid ? "is-invalid" : ""}
                        onBlur={() => fs.setIsTouched()}
                    />
                    <Note className="note-radio" note={opt.note} />
                </Fragment>
            ))}
            {props.schema.hasOtherOption && (
                <RadioWithTextField
                    name={name}
                    label="Other"
                    note={props.schema.otherOptionNote}
                    value={fs.value}
                    disabled={props.disabled}
                    isInvalid={validationResult.isInvalid}
                    onChange={value => fs.setValue(value)}
                    onBlur={() => fs.setIsTouched()}
                />
            )}
        </>
    );
}

interface RadioWithTextFieldProps {
    name: string;
    label: string;
    note?: string;
    value: RadioListFieldValue;
    disabled?: boolean;
    isInvalid: boolean;
    onChange: (value: RadioListFieldValue) => void;
    onBlur: () => void;
}

function RadioWithTextField(props: RadioWithTextFieldProps) {
    const [textValue, setTextValue] = useState(props.value.isOtherOption ? props.value.actualValue ?? "" : "");
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChecked = () => {
        props.onChange(new RadioListFieldValue(textValue, true));
        setTimeout(() => inputRef.current?.focus(), 1);
    };

    const handleTextChange = (textValue: string) => {
        setTextValue(textValue);
        props.onChange(new RadioListFieldValue(textValue, true));
    };

    useEffectOnUpdate(() => {
        if (props.value == null || typeof props.value !== "object") {
            setTextValue("");
        }
    }, [props.value]);

    return (
        <>
            <Radio
                name={props.name}
                label={props.label}
                checked={props.value.isOtherOption}
                disabled={props.disabled}
                onChecked={handleChecked}
                className={"itf-radio-input " + (props.isInvalid ? "is-invalid" : "")}
                onBlur={props.onBlur}
            />
            <div className="itf-radio-option-other">
                <Note note={props.note} />
                <input
                    ref={inputRef}
                    type="text"
                    className={"itf-text-input " + (props.value.isOtherOption && props.isInvalid ? "is-invalid" : "")}
                    value={textValue}
                    disabled={!props.value.isOtherOption || props.disabled}
                    onChange={e => handleTextChange(e.target.value)}
                    onBlur={props.onBlur}
                    placeholder="Specify"
                />
            </div>
        </>
    );
}
