import React from "react";
import { FormState, StepSchema } from "@forms/types";
import { isStepValid } from "@forms/helpers/validationHelpers";
import { preventDefault } from "@common";
import Icon from "@forms/styles/Icon";

interface Props {
    primaryColor: string;
    secondaryColor: string;
    visibleSteps: StepSchema[];
    currentStepIndex: number;
    formState: FormState;
    onStepChange: (index: number) => void;
}

export default function StepList(props: Props) {
    return (
        <div className="itf-step-list">
            {props.visibleSteps.map((s, i) => {
                const isValid = props.currentStepIndex >= i && isStepValid(s, props.formState);
                const isInvalid = props.currentStepIndex > i && !isStepValid(s, props.formState);
                const isCurrentStep = props.currentStepIndex === i;

                const className = ["itf-step-list-item", isInvalid ? "is-invalid" : ""].filter(n => n).join(" ");

                const color = !isInvalid && (isValid || isCurrentStep) ? props.primaryColor : props.secondaryColor;
                const divStyle: React.CSSProperties = { color: color };
                if (isCurrentStep) divStyle.borderBottomColor = color;

                return (
                    <div key={i} className={className} style={divStyle}>
                        <a href="#" onClick={preventDefault(() => props.onStepChange(i))} style={{ color: color }}>
                            <span className="itf-step-number">{i + 1}.</span>
                            <span className="itf-step-label">{s.label}</span>
                            <span className="itf-step-icon">
                                {isValid && (
                                    <span className="is-valid">
                                        <Icon icon="faCircleCheck" />
                                    </span>
                                )}
                                {isInvalid && (
                                    <span className="is-invalid">
                                        <Icon icon="faTriangleExclamation" />
                                    </span>
                                )}
                            </span>
                        </a>
                    </div>
                );
            })}
        </div>
    );
}
