import { TeamServerModel } from "@forms/types";
import React from "react";

const initialValue: {
    teams: TeamServerModel[];
} = {
    teams: []
};

const context = React.createContext(initialValue);

export default context;
