export function handleCurrencyInputKeyboardEvent(e: React.KeyboardEvent<HTMLInputElement>) {
    const highlightingSomethingInTheInput = window.getSelection().toString().length;
    const input = e.target as HTMLInputElement;
    const currentValue = input.value as string;
    const position = input.selectionStart;
    const containsMinus = currentValue.includes("-");

    const dotPosition = currentValue.indexOf(".");
    const containsDot = dotPosition >= 0;
    const decimalPlaces = containsDot ? currentValue.split(".")[1].length : 0;
    const isTypingDecimalPlaces = position > dotPosition;
    const decimalPlacesAllowed = highlightingSomethingInTheInput ? decimalPlaces <= 2 : decimalPlaces < 2; // 2 Allowed as we are overwriting (not inserting)
    const numbersAllowed =
        (!containsMinus || position > 0) && //if there's a minus sign, numbers are only allowed after it
        (!isTypingDecimalPlaces || decimalPlacesAllowed); //only two decimal numbers are allowed

    if (numbersAllowed) {
        const numbers = [...Array(10).keys()].map(x => x.toString());

        //allow regular numbers
        if (!e.shiftKey && numbers.find(x => x === e.key)) {
            return true;
        }
    }

    //allow one negative sign at the start of the input
    if (position === 0 && !containsMinus && e.key === "-") return true;

    if (["Backspace", "Delete", "Tab", "Home", "End", ","].includes(e.key)) return true;

    if (e.key.startsWith("Arrow")) return true;

    //function keys
    if (e.key.startsWith("F") && e.key.length > 1) return true;

    if ((e.ctrlKey || e.metaKey) && ["a", "c", "v", "x", "y", "z"].includes(e.key)) return true;

    if (
        e.key === "." &&
        position > 0 && //a dot is not allowed at the start of the input
        !containsDot && //only one dot is allowed
        !(containsMinus && position === 1) && //a dot is not allowed after the minus sign
        !(currentValue.length - position > 2) //only two decimal places allowed
    ) {
        return true;
    }

    //disallow everything else
    e.preventDefault();
    return false;
}
