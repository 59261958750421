export default class DropdownListFieldValue {
    public readonly actualValue: string;

    constructor(selectedValue: string, public readonly isOtherOption: boolean) {
        this.actualValue = selectedValue ?? null;
    }

    toSerializable() {
        return {
            type: "ddl" as const,
            value: this.actualValue,
            isOther: this.isOtherOption
        };
    }

    exists(): boolean {
        return !!this.actualValue;
    }

    toServerStringValue(): string {
        return this.actualValue;
    }
}
