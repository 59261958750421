import { ConditionSchema } from "./conditionSchemaTypes";
import { QuestionSchema } from "./questionSchemaTypes";

export const InTouchFormSchemaVersion = "intouch-form-schema-v7";

export interface FormSchema {
    version: typeof InTouchFormSchemaVersion;
    steps: StepSchema[];
    finalStep: FinalStepSchema;
}

export interface StepSchema {
    label: string;
    blocks: BlockSchema[];
    conditions?: ConditionSchema[];
}

export interface FinalStepSchema {
    label: string;
    html: string;
    chainedFormButton?: {
        formGuid: string;
        text: string;
    };
}

export type BlockSchema = QuestionBlockSchema | HtmlBlockSchema | QuoteBlockSchema;

export interface QuestionBlockSchema {
    type: "question";
    question: QuestionSchema;
    conditions?: ConditionSchema[];
}

export interface HtmlBlockSchema {
    type: "html";
    html: string;
    conditions?: ConditionSchema[];
}

export interface QuoteBlockSchema {
    type: "quote";
    fees: FeeSchema[];
    conditions?: ConditionSchema[];
}

export interface FeeSchema {
    name: string;
    dataMarker: string;
    taxPercentage: number;
    calculation: string;
    note: string;
    conditions?: ConditionSchema[];
    convertToReceivable?: FeeConversionToReceivableSchema;
}

export interface FeeConversionToReceivableSchema {
    accountName: string;
    accountGuid: string;
    contactGuid?: string;
    contactName?: string;
}
