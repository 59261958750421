import { InTouchFormSchemaVersion, FormSchema } from "@forms/types";
import { tryParseJSON } from "@common";

export function isStringArray(arg: unknown): arg is string[] {
    return Array.isArray(arg) && arg.every(x => typeof x === "string");
}

export function tryParseFormSchema(jsonString: string): FormSchema {
    const data = tryParseJSON(jsonString);
    if (data == null || typeof data !== "object" || data.version !== InTouchFormSchemaVersion) {
        return null;
    }
    return data as FormSchema;
}

export function formatMoney(number: number) {
    const nDecimals = 2;
    const decimalMark = ".";
    const thousandsSep = ",";
    const sign = number < 0 ? "-" : "";
    number = Math.abs(number);
    const iNumber = parseInt(number.toFixed(nDecimals));
    const iString = String(iNumber);
    const j = iString.length > 3 ? iString.length % 3 : 0;

    return (
        sign +
        (j ? iString.substring(0, j) + thousandsSep : "") +
        iString.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSep) +
        decimalMark +
        Math.abs(number - iNumber)
            .toFixed(nDecimals)
            .slice(2)
    );
}
