import { shortId } from "@common";
import React, { useRef, useState } from "react";
import { DropdownListQuestionSchema, DropdownListFieldValue } from "../../types";
import useFieldState from "../../hooks/useFieldState";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function DropdownListField(props: FieldProps<DropdownListQuestionSchema>) {
    const [id] = useState(shortId());
    const fs = useFieldState<DropdownListFieldValue>(props);
    const inputRef = useRef<HTMLInputElement>(null);

    const validationResult = fs.getValidationResult();

    const theOtherValue = "the-other-value";
    const selectedValue = fs.value.isOtherOption ? theOtherValue : fs.value.actualValue ?? "";

    const handleChange = (value: string) => {
        if (value === theOtherValue) {
            fs.setValue(new DropdownListFieldValue("", true));
            setTimeout(() => inputRef.current?.focus(), 0);
        } else {
            fs.setValue(new DropdownListFieldValue(value, false));
        }
    };

    const handleOtherValueChange = (otherValue: string) => {
        fs.setValue(new DropdownListFieldValue(otherValue, true));
    };

    return (
        <>
            <FieldHeader {...props.schema} id={id} />
            <select
                className={"itf-select " + (validationResult.isInvalid ? "is-invalid" : "")}
                value={selectedValue}
                disabled={props.disabled}
                onChange={e => handleChange(e.target.selectedOptions[0].value)}
                onBlur={() => fs.setIsTouched()}>
                <option value="" disabled>
                    Select your option
                </option>
                {props.schema.options.map((opt, i) => (
                    <option key={i} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
                {props.schema.hasOtherOption && <option value={theOtherValue}>Other</option>}
            </select>
            {fs.value.isOtherOption && (
                <div className="itf-ddl-other">
                    <label className="itf-label" htmlFor={id}>
                        Please Specify {props.schema.required && <span className="itf-required">*</span>}
                    </label>
                    <input
                        ref={inputRef}
                        type="text"
                        className={"itf-text-input " + (validationResult.isInvalid ? "is-invalid" : "")}
                        value={fs.value.actualValue ?? ""}
                        disabled={props.disabled}
                        onChange={e => handleOtherValueChange(e.target.value)}
                        onBlur={() => fs.setIsTouched()}
                    />
                </div>
            )}
            <ValidationMessage {...validationResult} />
        </>
    );
}
