import { shortId } from "@common";
import React, { useState } from "react";
import {
    EmailQuestionSchema,
    PhoneNumberQuestionSchema,
    TextFieldValue,
    TextQuestionSchema,
    TimeQuestionSchema
} from "src/forms/types";
import useFieldState from "../../hooks/useFieldState";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function TextField(
    props: FieldProps<TextQuestionSchema | EmailQuestionSchema | PhoneNumberQuestionSchema | TimeQuestionSchema>
) {
    const [id] = useState("text-input-" + shortId());

    const fs = useFieldState<TextFieldValue>(props);

    const validationResult = fs.getValidationResult();

    const maxLength = props.schema.type === "phone-number" ? 20 : null;

    return (
        <>
            <FieldHeader {...props.schema} id={id} />
            <input
                type="text"
                id={id}
                className={"itf-text-input " + (validationResult.isInvalid ? "is-invalid" : "")}
                value={fs.value.actualValue}
                disabled={props.disabled}
                onChange={e => fs.setValue(fs.value.update(e.target.value))}
                onBlur={() => fs.setIsTouched()}
                maxLength={maxLength}
            />
            <ValidationMessage {...validationResult} />
        </>
    );
}
