import React from "react";

interface HourPickerProps {
    value: number;
    disabled?: boolean;
    onChange: (value: number) => void;
    hasEmptyOption: boolean;
    className?: string;
    onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

const hoursArr = [...Array(24).keys()];

export default function HourPicker(props: HourPickerProps) {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "") {
            props.onChange(null);
        } else {
            props.onChange(parseInt(e.target.value));
        }
    };

    return (
        <select
            className={"itf-select itf-hour-select " + (props.className ?? "")}
            value={props.value === null ? "" : props.value}
            disabled={props.disabled}
            onChange={handleChange}
            onBlur={props.onBlur}>
            {props.hasEmptyOption && <option value="">Hour</option>}
            {hoursArr.map(h => {
                return (
                    <option key={h} value={h}>
                        {h.toString().length < 2 ? `0${h}` : h.toString()}
                    </option>
                );
            })}
        </select>
    );
}
