import { FormSchema, QuestionBlockSchema, QuoteBlockSchema } from "@forms/types";
import { extractDataMarkersFromQuestion } from "@forms/helpers/questionFn";

const findQuestionBlockByDataMarker = (formSchema: FormSchema, dataMarker: string): QuestionBlockSchema => {
    const block = formSchema.steps
        .flatMap(s => s.blocks)
        .flatMap(b => (b.type === "question" ? [b] : []))
        .find(b => extractDataMarkersFromQuestion(b.question).includes(dataMarker));

    return block;
};

const findQuoteBlock = (formSchema: FormSchema): QuoteBlockSchema => {
    const block = formSchema.steps.flatMap(s => s.blocks).flatMap(b => (b.type === "quote" ? [b] : []))[0];
    return block;
};

const hasFeeEarnerQuestion = (formSchema: FormSchema): boolean => {
    return formSchema.steps.flatMap(s => s.blocks).some(b => b.type === "question" && b.question.type === "fee-earner");
};

export default {
    findQuestionBlockByDataMarker,
    findQuoteBlock,
    hasFeeEarnerQuestion
};
