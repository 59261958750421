import Icon from "@forms/styles/Icon";
import React from "react";

export default function FieldDataMarker(props: { dataMarker: string }) {
    // "do not save" data markers start with "__". no need to display them
    if (props.dataMarker.startsWith("__")) {
        return null;
    }

    return (
        <div className="text-meta-info" title="Data Marker">
            <Icon icon="faFloppyDisk" />
            <small>{props.dataMarker}</small>
        </div>
    );
}
