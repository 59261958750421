import React, { useState } from "react";
import LoadingIcon from "@common/components/LoadingIcon";
import Icon from "@forms/styles/Icon";

interface Props {
    onClick: () => Promise<void>;
}

export default function ClearAnswersButton(props: Props) {
    const [working, setWorking] = useState(false);

    const handleClick = async () => {
        try {
            setWorking(true);
            await props.onClick();
        } finally {
            setWorking(false);
        }
    };

    return (
        <button type="button" className="itf-button itf-button-reset" onClick={handleClick}>
            {working ? <LoadingIcon size={38} /> : <Icon icon="faTrash" />}
            <span>Clear My Answers</span>
        </button>
    );
}
