import { preventDefault } from "@common";
import React from "react";
import { FileFieldValue, FileQuestionSchema } from "../../types";
import useFieldState from "../../hooks/useFieldState";
import FileUploadButton from "../FileUploadButton";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";
import Icon from "@forms/styles/Icon";

export default function FileField(props: FieldProps<FileQuestionSchema>) {
    const fs = useFieldState<FileFieldValue>(props);

    const validationResult = fs.getValidationResult();

    const clear = (file: File) => {
        const newValue = new FileFieldValue(fs.value.files.filter(f => f !== file));
        fs.setValue(newValue);
    };

    return (
        <>
            <FieldHeader {...props.schema} />
            <div className="itf-file-question">
                {fs.value.files.map((f, i) => {
                    return (
                        <div className="itf-file-question-file" key={i}>
                            <Icon icon="faFileAlt" />
                            {f.name}
                            <a href="#" onClick={preventDefault(() => clear(f))}>
                                <Icon icon="faXmark" />
                            </a>
                        </div>
                    );
                })}
                <ValidationMessage {...validationResult} />
                <FileUploadButton
                    multiple
                    disabled={props.disabled}
                    onFileSelect={addedFiles => {
                        const existingFiles: File[] = [];
                        const newFiles = Array.from(addedFiles);
                        for (const existing of fs.value.files) {
                            const match = newFiles.find(nf => nf.name === existing.name);
                            if (!match) existingFiles.push(existing);
                        }

                        fs.setValue(new FileFieldValue([...existingFiles, ...newFiles]));
                        fs.setIsTouched();
                    }}
                    onBlur={fs.setIsTouched}>
                    Add File
                </FileUploadButton>
            </div>
        </>
    );
}
