import { debounce } from "@common";
import fileDb from "./fileDb";
import {
    FormState,
    PersistedFieldState,
    PersistedFieldValue,
    PersistedFormState,
    QuestionBlockSchema,
    StepSchema
} from "@forms/types";

const key = (sessionKey: string) => `intouch-form-state-${sessionKey}`;

const getSessionKey = (formGuid: string, matterGuid: string) => {
    if (matterGuid) {
        return formGuid + "-" + matterGuid;
    }
    return formGuid;
};

const save = (sessionKey: string, formState: FormState) => {
    const data = makeFormSerializable(formState);
    localStorage.setItem(key(sessionKey), JSON.stringify(data));
};

const debouncedSave = debounce(save, 300);

const read = async (sessionKey: string) => {
    const persistedFormState = readFormStateFromLocalStorage(sessionKey);
    if (!persistedFormState) return null;

    const fileRecords = await fileDb.get(sessionKey);
    for (const record of fileRecords) {
        const entry = persistedFormState[record.dataMarker];
        if (entry) {
            entry.value = record.files;
        }
    }
    return persistedFormState;
};

const exists = (sessionKey: string, steps: StepSchema[]) => {
    const state = readFormStateFromLocalStorage(sessionKey);

    if (!state) return false;

    for (const key in state) {
        const field: PersistedFieldState = state[key];
        const value: PersistedFieldValue = field.value;

        //false can be considered as value
        const hasValue = !!value || value === false;

        if (!field.isTouched && !hasValue) {
            //a field may have an answer, but not be flagged as touched.
            //i.e. type in an input, then immediately close the form
            //before onblur
            continue;
        }

        if (typeof value === "string" && value.length) {
            return true;
        }
        if (value === true || value === false) {
            if (value === false) {
                const isCheckbox = !!steps?.find(s =>
                    s.blocks.find(
                        b => b.type === "question" && (b as QuestionBlockSchema).question?.type === "checkbox"
                    )
                );

                //a checkbox, with a value of false is not considered to be an answer
                //but a yes/no is set to false is an answer
                if (isCheckbox) {
                    continue;
                }
            }
            return true; //true and false to both count as a value
        }

        if (typeof value === "object" && value !== null) {
            if (Array.isArray(value) && value.length > 0) return true; //has file value
            const typeFreeValue = value as any;
            if (typeFreeValue.radioValue || typeFreeValue.radioIsOther) return true; //has radio value
            if (typeFreeValue.values && Array.isArray(typeFreeValue.values) && typeFreeValue.values.length > 0)
                return true; //has multiselect value
            if (typeFreeValue.value) return true; //has ddl value
            if (
                typeFreeValue.pointGroups &&
                Array.isArray(typeFreeValue.pointGroups) &&
                typeFreeValue.pointGroups.length > 0
            )
                return true; //has esignature value
        }
    }
    return false;
};

const drop = async (sessionKey: string) => {
    localStorage.removeItem(key(sessionKey));
    await fileDb.remove(sessionKey);
};

const saveFile = (sessionKey: string, dataMarker: string, files: readonly File[]) => {
    return fileDb.save(sessionKey, dataMarker, files);
};

export default {
    save: (formGuid: string, matterGuid: string, formState: FormState) =>
        debouncedSave(getSessionKey(formGuid, matterGuid), formState),
    read: (formGuid: string, matterGuid: string) => read(getSessionKey(formGuid, matterGuid)),
    exists: (formGuid: string, matterGuid: string, steps: StepSchema[]) =>
        exists(getSessionKey(formGuid, matterGuid), steps),
    drop: (formGuid: string, matterGuid: string) => drop(getSessionKey(formGuid, matterGuid)),
    saveFile: (formGuid: string, matterGuid: string, dataMarker: string, files: readonly File[]) =>
        saveFile(getSessionKey(formGuid, matterGuid), dataMarker, files)
};

const readFormStateFromLocalStorage = (sessionKey: string): PersistedFormState => {
    const obj = localStorage.getItem(key(sessionKey));
    let result = null;

    try {
        result = JSON.parse(obj);
    } catch {
        localStorage.removeItem(key(sessionKey));
    }

    if (typeof result === "object") {
        return result;
    }

    return null;
};

const makeFormSerializable = (formState: FormState) => {
    const result: PersistedFormState = {};

    for (const [dataMarker, { isTouched, value }] of Object.entries(formState)) {
        result[dataMarker] = {
            isTouched,
            value: value.toSerializable()
        };
    }

    return result;
};
