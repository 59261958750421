import React from "react";
import stringFn from "@common/stringFn";

export function getClipboardAsNumber(e: React.ClipboardEvent<HTMLElement>) {
    e.preventDefault();

    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const text = clipboardData.getData("text");
    const sanitised = text?.replace(/[^\d.-]/g, "");

    const num = parseFloat(sanitised);

    return {
        hasValue: !isNaN(num),
        value: sanitised
    };
}

export function getClipboardAsCurrency(e: React.ClipboardEvent<HTMLElement>) {
    e.preventDefault();

    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const text = clipboardData.getData("text");
    const sanitised = text?.replace(/[^\d,.-]/g, "");

    const num = parseFloat(stringFn.replaceAll(sanitised, ",", ""));

    return {
        hasValue: !isNaN(num),
        value: sanitised
    };
}
