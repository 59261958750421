export default class TextFieldValue {
    public readonly actualValue: string;

    constructor(actualValue: string, public readonly type: "text" | "textarea" | "email" | "phone-number" | "time") {
        this.actualValue = actualValue ?? "";
    }

    update(newValue: string) {
        return new TextFieldValue(newValue, this.type);
    }

    toSerializable() {
        return this.actualValue;
    }

    exists(): boolean {
        return !!this.actualValue;
    }

    toServerStringValue(): string {
        return this.actualValue;
    }
}
