import { assertNever } from "@common";
import { BaseNumberInputValue } from "../components/components.types";
import {
    BlockSchema,
    ConditionSchema,
    FormState,
    FeeSchema,
    StepSchema,
    QuestionBlockSchema,
    YesNoFieldValue,
    RadioListFieldValue,
    CheckboxFieldValue,
    DropdownListFieldValue,
    MultiselectFieldValue,
    FieldValue
} from "../types";

export const areAllConditionsSatisfied = (conditions: ConditionSchema[], formState: FormState): boolean => {
    return conditions.every(c => isConditionSatisfied(c, formState[c.dataMarker].value));
};

const isConditionSatisfied = (condition: ConditionSchema, value: FieldValue): boolean => {
    if (value instanceof YesNoFieldValue) {
        return isYesNoFieldOrCheckboxFieldConditionSatisfied(condition, value);
    }

    if (value instanceof RadioListFieldValue) {
        return isMultipleChoiceListFieldConditionSatisfied(condition, value);
    }

    if (value instanceof BaseNumberInputValue) {
        return isNumericFieldConditionSatisfied(condition, value);
    }

    if (value instanceof CheckboxFieldValue) {
        return isYesNoFieldOrCheckboxFieldConditionSatisfied(condition, value);
    }

    if (value instanceof DropdownListFieldValue) {
        return isMultipleChoiceListFieldConditionSatisfied(condition, value);
    }

    if (value instanceof MultiselectFieldValue) {
        return isMultiselectFieldConditionSatisfied(condition, value);
    }

    return false;
};

const isYesNoFieldOrCheckboxFieldConditionSatisfied = (
    condition: ConditionSchema,
    value: YesNoFieldValue | CheckboxFieldValue
) => {
    switch (condition.operator) {
        case "equals":
            return value.actualValue === condition.value;
        default:
            return false;
    }
};

const isMultipleChoiceListFieldConditionSatisfied = (
    condition: ConditionSchema,
    value: DropdownListFieldValue | RadioListFieldValue
) => {
    switch (condition.operator) {
        case "equals":
            return (
                typeof condition.value === "string" &&
                value.actualValue?.toLowerCase() === condition.value.toLowerCase()
            );
        case "is-one-of":
            if (!Array.isArray(condition.value)) return false;

            const actualValueLower = value.actualValue?.toLowerCase();
            return condition.value.some(v => v.toLowerCase() === actualValueLower);
        default:
            return false;
    }
};

const isNumericFieldConditionSatisfied = (condition: ConditionSchema, value: BaseNumberInputValue) => {
    if (!value.exists()) return false;

    if (typeof condition.value !== "number") return false;

    switch (condition.operator) {
        case "equals":
            return value.parsedValue === condition.value;
        case "greater-or-equal":
            return value.parsedValue >= condition.value;
        case "greater-than":
            return value.parsedValue > condition.value;
        case "less-or-equal":
            return value.parsedValue <= condition.value;
        case "less-than":
            return value.parsedValue < condition.value;
        default:
            return false;
    }
};

const isMultiselectFieldConditionSatisfied = (condition: ConditionSchema, value: MultiselectFieldValue) => {
    switch (condition.operator) {
        case "has-any-of-the-following-selected":
            if (!Array.isArray(condition.value)) return false;

            const selectedValuesLower = [...value.selectedValues, value.otherValue].map(v => v.toLowerCase());
            const conditionValuesLower = condition.value.map(v => v.toLowerCase());

            return selectedValuesLower.some(sv => conditionValuesLower.includes(sv));
        default:
            return false;
    }
};

/**
 * This function looks up recursive conditions and flattens them for each question
 * making it easier to figure out when to show or hide a particular question
 * and also to determine when an answer to the question is required
 */
export const wireUpConditions = (steps: StepSchema[]): StepSchema[] => {
    const questionMap = new Map<string, QuestionBlockSchema>();

    const newSteps: StepSchema[] = [];
    for (const step of steps) {
        newSteps.push(mapStep(step));
    }

    return newSteps;

    function mapStep(step: StepSchema): StepSchema {
        const newStepConditions = getAllConditions(step.conditions ?? [], questionMap);

        const newBlocks: BlockSchema[] = [];
        for (const block of step.blocks) {
            newBlocks.push(mapBlock(block, newStepConditions));
        }

        return {
            ...step,
            blocks: newBlocks,
            conditions: newStepConditions
        };
    }

    function mapBlock(block: BlockSchema, stepConditions: ConditionSchema[]): BlockSchema {
        switch (block.type) {
            case "question": {
                const questionBlockConditions = block.conditions ?? [];

                const newConditions = getAllConditions(stepConditions.concat(questionBlockConditions), questionMap);

                const newBlock = {
                    ...block,
                    conditions: newConditions
                };

                if (block.question.type === "address") {
                    questionMap.set(block.question.dataMarkerAddress1, newBlock);
                    questionMap.set(block.question.dataMarkerAddress2, newBlock);
                    questionMap.set(block.question.dataMarkerAddress3, newBlock);
                    questionMap.set(block.question.dataMarkerAddress4, newBlock);
                    questionMap.set(block.question.dataMarkerPostcode, newBlock);
                } else {
                    questionMap.set(block.question.dataMarker, newBlock);
                }

                return newBlock;
            }
            case "html": {
                return {
                    ...block,
                    conditions: getAllConditions(block.conditions ?? [], questionMap)
                };
            }
            case "quote": {
                return {
                    ...block,
                    fees: block.fees.map(mapFee),
                    conditions: getAllConditions(block.conditions ?? [], questionMap)
                };
            }
            default:
                assertNever(block);
        }
    }

    function mapFee(fee: FeeSchema) {
        return {
            ...fee,
            conditions: getAllConditions(fee.conditions ?? [], questionMap)
        };
    }

    function getAllConditions(conditions: ConditionSchema[], questionMap: Map<string, QuestionBlockSchema>) {
        const allConditions = [...conditions];

        for (const c of conditions) {
            const questionBlock = questionMap.get(c.dataMarker);
            const otherConditions = questionBlock?.conditions ?? [];
            allConditions.push(...otherConditions);
        }

        return allConditions;
    }
};
