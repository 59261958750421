import React, { useContext, useState } from "react";
import { FieldState, FormModel, FormState, StepSchema } from "@forms/types";
import Block from "@forms/components/Block";
import ConfirmationButton from "@forms/components/ConfirmationButton";
import { isStepValid } from "@forms/helpers/validationHelpers";
import Spinner from "../Spinner";
import Loader from "../Loader";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import formStateFn from "@forms/helpers/formStateFn";
import { useEffectOnFirstRender } from "@common";
import FormsAppConfigContext from "@forms/state/FormsAppConfigContext";
import Icon from "@forms/styles/Icon";

interface Prop {
    step: StepSchema;
    stepIndex: number;
    form: FormModel;
    formState: FormState;
    visibleSteps: StepSchema[];
    setFieldState: (dataMarker: string, fieldState: Partial<FieldState>) => void;
    setAllTouched: () => void;
    onPrevious: () => void;
    onNext: () => void;
    onSubmit: () => Promise<void>;
    agreesToTermsOfService: boolean;
    onAgreesToTermsOfServiceChange: (b: boolean) => void;
}

export default function Step(props: Prop) {
    const { config } = useContext(FormsAppConfigContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [htmlBlockMap, setHtmlBlockMap] = useState<{ [index: string]: string }>();

    const handleSubmit = () => {
        setIsSubmitting(true);
        props.onSubmit().finally(() => setIsSubmitting(false));
    };

    useEffectOnFirstRender(() => {
        getHtmlBlockMap(config.matterGuid, props.form, props.step, props.formState)
            .then(result => setHtmlBlockMap(result))
            .finally(() => setIsLoading(false));
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {props.step.blocks.map((b, i) => (
                <Block
                    key={i}
                    block={b}
                    form={props.form}
                    formState={props.formState}
                    setFieldState={props.setFieldState}
                    html={b.type === "html" ? htmlBlockMap[b.html] ?? b.html : null}
                />
            ))}
            <div style={{ position: "absolute", top: "-1000px", left: "-1000px", opacity: 0 }}>
                <input
                    type="checkbox"
                    id="agreesToTermsOfService"
                    className="itf-checkbox-input"
                    checked={props.agreesToTermsOfService}
                    onChange={e => props.onAgreesToTermsOfServiceChange(e.target.checked)}
                />
                <label htmlFor="agreesToTermsOfService" className="itf-label">
                    I agree to the <a href="https://www.intouch.cloud/">terms of service</a>
                </label>
            </div>
            <div className="itf-step-actions">
                {props.stepIndex !== 0 && (
                    <button
                        className="itf-button"
                        type="button"
                        onClick={props.onPrevious}
                        style={{ color: props.form.secondaryColor }}>
                        <Icon icon="faLeft" />
                        Previous
                    </button>
                )}
                {props.stepIndex < props.visibleSteps.length - 1 && (
                    <ConfirmationButton
                        color={props.form.tertiaryColor}
                        label="Next"
                        requiresConfirmation={() => {
                            props.setAllTouched();
                            return !isStepValid(props.step, props.formState);
                        }}
                        onClick={props.onNext}
                        tooltipText={
                            <span>
                                This step is not complete
                                <br /> Press the button again if you wish to continue
                            </span>
                        }
                    />
                )}
                {props.stepIndex === props.visibleSteps.length - 1 && (
                    <button
                        className="itf-button"
                        style={{ backgroundColor: props.form.tertiaryColor, borderColor: props.form.tertiaryColor }}
                        type="button"
                        disabled={isSubmitting}
                        onClick={handleSubmit}>
                        Submit
                        {isSubmitting ? <Spinner /> : <Icon icon="faCircleCheck" />}
                    </button>
                )}
            </div>
        </>
    );
}

async function getHtmlBlockMap(
    matterGuid: string,
    form: FormModel,
    stepSchema: StepSchema,
    formState: FormState
): Promise<{ [index: string]: string }> {
    const blocks = stepSchema.blocks.flatMap(b => (b.type === "html" && b.html.includes("{{") ? [b] : []));

    if (!blocks.length) {
        return {};
    }

    const { data } = formStateFn.toCompletionData(formState, form.schema);

    const response = await apiEmbeddedInTouchForms.EmbeddedInTouchForms.CompileHtmlBlocks({
        matterGuid,
        htmlTemplates: blocks.map(b => b.html),
        data
    });

    if (!response.success) {
        return {};
    }

    const result: { [index: string]: string } = {};

    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        result[block.html] = response.data[i];
    }

    return result;
}
