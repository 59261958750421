import { useEffectOnUpdate, preventDefault } from "@common";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import { AddressServerModel } from "@forms/types";
import React, { useState } from "react";
import LoadingIcon from "@common/components/LoadingIcon";

interface Props {
    addressFieldsVisible: boolean;
    disabled: boolean;
    onChange: (address: AddressServerModel) => void;
    onBlur: () => void;
    onEnterManually: () => void;
}

export default function AddressLookupAus(props: Props) {
    const [search, setSearch] = useState("");
    const [loading, setIsLoading] = useState(false);
    const [addresses, setAddresses] = useState<{ id?: string; caption?: string }[]>([]);
    const [selectedAddressId, setSelectedAddressId] = useState("");

    useEffectOnUpdate(() => {
        setIsLoading(true);
        const timerId = setTimeout(performSearch, 300);
        return () => clearTimeout(timerId);
    }, [search]);

    const performSearch = async () => {
        try {
            if (!search) {
                return;
            }

            const resp = await apiEmbeddedInTouchForms.InTouchFormAddressLookup.FindSuggestions({ value: search });
            if (resp.success) {
                setAddresses(resp.data);
            } else {
                setAddresses([]);
            }
            setSelectedAddressId("");
        } catch (e) {
            setAddresses([]);
            setSelectedAddressId("");
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    const onAddressChange = async (id: string) => {
        setSelectedAddressId(id);
        setIsLoading(true);
        try {
            const result = await apiEmbeddedInTouchForms.InTouchFormAddressLookup.GetFullInfo({ value: id });
            if (result.success) {
                props.onChange(result.data);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="itf-address-lookup">
            <div>
                <input
                    type="text"
                    className="itf-text-input"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    placeholder="Search"
                />
            </div>
            {loading ? (
                <div className="itf-address-loader">
                    <LoadingIcon size={25} />
                </div>
            ) : (
                search && (
                    <>
                        <div className="itf-address-select">
                            <label className="itf-label">{addresses.length} matching addresses found</label>
                            <select
                                className="itf-select"
                                disabled={addresses.length === 0}
                                value={selectedAddressId}
                                onChange={e => onAddressChange(e.target.value)}>
                                <option disabled value="">
                                    Select an address
                                </option>
                                {addresses.map(a => (
                                    <option key={a.id} value={a.id}>
                                        {a.caption}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                )
            )}
            {!props.addressFieldsVisible && (
                <a className="itf-address-button" href="#" onClick={preventDefault(props.onEnterManually)}>
                    Enter Address
                </a>
            )}
        </div>
    );
}
