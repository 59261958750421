import { FormsAppConfig } from "@forms/types";
import React from "react";

const initialValue: {
    config: FormsAppConfig;
} = {
    config: {
        guid: null,
        matterGuid: null,
        token: null,
        container: null,
        serverUrl: null,
        submissionData: null,
        country: "UK",
        events: {
            onSubmitted: null,
            onStepChange: null
        }
    }
};

const context = React.createContext(initialValue);

export default context;
