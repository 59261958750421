import React from "react";
import ReactDOM from "react-dom/client";
import { deferred } from "@common";
import { ModalApp } from "./ModalApp";
import { ModalComponentConstructor } from "./types";

let parent: HTMLElement;

function init(config: { parent: HTMLElement }) {
    parent = config.parent;
}

function create(component: ModalComponentConstructor): Promise<boolean> {
    const container = document.createElement("div");
    parent.append(container);

    const root = ReactDOM.createRoot(container);
    root.render(
        <ModalApp
            backdropStyle={
                parent !== document.body ? { height: parent.offsetHeight + "px", width: parent.offsetWidth + "px" } : {}
            }
            component={component}
            onConfirm={confirm}
            onCancel={cancel}
        />
    );

    const successDeferred = deferred<boolean>();
    successDeferred.promise.catch(console.error);
    return successDeferred.promise;

    function remove() {
        root?.unmount();
        container.remove();
    }

    function confirm() {
        remove();
        successDeferred.resolve(true);
    }

    function cancel() {
        remove();
        successDeferred.resolve(false);
    }
}

export default {
    init,
    create
};
