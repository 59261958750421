import { queryString, deferred, Dictionary, logger } from "@common";

interface HttpOptions {
    baseUrl: string;
    on400: ErrorHandlerFn;
    on401: ErrorHandlerFn;
    on404: ErrorHandlerFn;
    onUnknownError: ErrorHandlerFn;
    modifyHeaders?: ModifyHeadersFn;
}
type ModifyHeadersFn = (headers: Dictionary<string>) => void;
type ErrorHandlerFn = (errorMessage: string) => void;

const config: HttpOptions & {
    isInitialised?: true;
} = {} as any;

const checkResponse = async (
    httpResponse: Response,
    customResponseHandler: (resp: Response) => { handled: boolean }
): Promise<{ httpResponse: Response; message: string }> => {
    if (customResponseHandler != null) {
        const { handled } = customResponseHandler(httpResponse);
        if (handled) {
            return { httpResponse, message: "" };
        }
    }

    if (httpResponse.status === 200) {
        return { httpResponse, message: "" };
    }

    if (httpResponse.status === 401) {
        const message = (await httpResponse.json()).message ?? "Unauthorised";
        logger.warn(message);
        config.on401(message);
        return { httpResponse, message };
    }

    let errorMessage =
        "An unknown error occured. Try refreshing the page and ensuring you have an internet connection. ";

    const responseText = await httpResponse.text();

    if (responseText) {
        try {
            const errorObject = JSON.parse(responseText);

            if (errorObject) {
                if (errorObject.errors && errorObject.errors.length) {
                    //reset to first error
                    errorMessage = errorObject.errors[0];
                } else if (errorObject.message) {
                    //Fall back to the response message
                    errorMessage = errorObject.message;
                }
            }
        } catch (ex) {
            //fail silently
            console.error("Unhandled HTTP error response", ex);
        }
    }

    if (httpResponse.status === 400) {
        logger.debug(errorMessage);
        config.on400(errorMessage);
    } else if (httpResponse.status === 404) {
        logger.debug(errorMessage);
        config.on404(errorMessage);
    } else {
        logger.debug(errorMessage);
        config.onUnknownError(errorMessage);
    }

    return { httpResponse, message: errorMessage };
};

const f = (
    method: "GET" | "POST" | "DELETE",
    url: string,
    bodyData?: any,
    customResponseHandler?: (resp: Response) => { handled: boolean }
): Promise<{ httpResponse: Response; message: string }> => {
    if (!config.isInitialised) logger.warn("http.ts is not initialised.");

    const headers: { [key: string]: string } = {};

    const request: RequestInit = {
        method,
        headers
    };

    if (bodyData instanceof FormData) {
        request.body = bodyData;
    } else {
        headers["Content-Type"] = "application/json";
        request.body = JSON.stringify(bodyData);
    }

    const d = deferred<{ httpResponse: Response; message: string }>();

    if (config.modifyHeaders) {
        config.modifyHeaders(headers);
    }

    const fullyQualifiedUrl = config.baseUrl + url;

    fetch(fullyQualifiedUrl, request)
        .then(resp => checkResponse(resp, customResponseHandler))
        .then(d.resolve)
        .catch(reason => {
            //network failure
            const message = `Failed to connect to ${fullyQualifiedUrl}. ${reason}`;
            config.onUnknownError(message);
            d.reject(message);
        });

    return d.promise;
};

export default {
    init: (options: HttpOptions) => {
        Object.assign(config, options);
        config.isInitialised = true;
    },
    get: async (config: { url: string; data?: any }): Promise<any> => {
        let url = config.url;
        if (config.data) {
            url += `?${queryString.newParams(config.data)}`;
        }

        const { httpResponse, message } = await f("GET", url);

        if (httpResponse.status === 200) {
            return await httpResponse.json();
        }

        return { success: false, statusCode: httpResponse.status, message };
    },
    post: async (config: { url: string; data?: any }): Promise<any> => {
        const { httpResponse, message } = await f("POST", config.url, config.data);

        if (httpResponse.status === 200) {
            return await httpResponse.json();
        }

        return { success: false, statusCode: httpResponse.status, message };
    },
    postFile: async (config: {
        url: string;
        file: any;
        customResponseHandler?: (resp: Response) => { handled: boolean };
    }): Promise<any> => {
        if (!config || !config.file) {
            throw "file not included";
        }

        const formData = new FormData();
        formData.append("file", config.file);

        const { httpResponse, message } = await f("POST", config.url, formData, config.customResponseHandler);

        if (httpResponse.status === 200) {
            return await httpResponse.json();
        }

        return { success: false, statusCode: httpResponse.status, message };
    },
    postFiles: async (config: { url: string; files: readonly File[] }): Promise<any> => {
        if (!config || !config.files || config.files.length === 0) {
            throw new Error("file not included");
        }

        const formData = new FormData();
        for (const file of config.files) {
            formData.append("files", file);
        }

        const { httpResponse, message } = await f("POST", config.url, formData);

        if (httpResponse.status === 200) {
            return await httpResponse.json();
        }

        return { success: false, statusCode: httpResponse.status, message };
    },
    del: async (config: { url: string; data?: any }): Promise<any> => {
        const { httpResponse, message } = await f("DELETE", config.url, config.data);

        if (httpResponse.status === 200) {
            return await httpResponse.json();
        }

        return { success: false, statusCode: httpResponse.status, message };
    }
};
