import SignaturePad, { PointGroup } from "signature_pad";

export default class EsignatureFieldValue {
    public readonly pointGroups: readonly PointGroup[];
    private _size: number = null;

    constructor(
        pointGroups: readonly PointGroup[],
        private readonly canvasHeight: number,
        private readonly canvasWidth: number
    ) {
        this.pointGroups = pointGroups ?? [];
    }

    get size() {
        this._size ??= this.pointGroups.map(g => g.points.length).reduce((x, y) => x + y, 0);
        return this._size;
    }

    toSerializable() {
        return {
            type: "point-groups" as const,
            pointGroups: this.pointGroups,
            canvasHeight: this.canvasHeight,
            canvasWidth: this.canvasWidth
        };
    }

    exists(): boolean {
        return this.size !== 0;
    }

    toBase64() {
        if (!this.exists()) {
            return null;
        }

        const canvas = document.createElement("canvas");
        canvas.height = this.canvasHeight;
        canvas.width = this.canvasWidth;
        const pad = new SignaturePad(canvas);
        pad.fromData([...this.pointGroups]);
        const base64 = pad.toDataURL();
        return base64;
    }
}
