import React, { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    error: string | Error;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: string | Error): State {
        // Logging is already handled by global error event handler.
        return { error: error };
    }

    render() {
        if (this.state.error != null) {
            console.log(this.state.error);
            return (
                <div className="m-4 alert alert-danger">
                    <h4 className="alert-heading">Something went wrong</h4>
                    <p>An unknown error occured</p>
                </div>
            );
        }

        return this.props.children;
    }
}
