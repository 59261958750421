import logger from "@common/logger";

/**
 * Responsile for datetime logic helpers
 */
const dateFormat = "DD/MM/YYYY";

const dateFormatUrlSafe = "YYYY-DD-MM";
const dateTimeFormat = "h:mma DD/MM/YYYY";

function clone(date: Date) {
    return new Date(date.getTime());
}

const getDaysBetweenDates = (start: Date, end: Date): number => {
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24));
};

const getMonthsBetweenDates = (initialStartDate: Date, end: Date): string[] => {
    const start = clone(initialStartDate);
    const months = [];

    while (start <= end) {
        const month = start.toLocaleString("default", {
            month: "long",
            year: "numeric"
        });
        months.push(month);
        start.setMonth(start.getMonth() + 1);
    }

    // Check if the end date's month is included, if not, add it
    const lastMonth = end.toLocaleString("default", {
        month: "long",
        year: "numeric"
    });
    if (!months.includes(lastMonth)) {
        months.push(lastMonth);
    }

    return months;
};

const isValidIsoDateTimeStringOrNull = (isoDateString: string) => {
    if (isoDateString && isoDateString.charAt(isoDateString.length - 1).toLowerCase() !== "z") {
        return false;
    }
    return true;
};

const isTimeString = (input: unknown): input is string => {
    if (typeof input !== "string") return false;

    const [hours, minutes] = input.split(":").map(x => parseInt(x));

    if (typeof hours !== "number" || hours < 0 || hours > 23) {
        return false;
    }

    if (typeof minutes !== "number" || minutes < 0 || minutes > 59) {
        return false;
    }

    return true;
};

const isDateString = (input: unknown): input is string => {
    if (typeof input !== "string") return false;

    const [year, month, date] = input.split("-");

    if (!year || year.length !== 4) return false;

    if (!month || month.length !== 2) return false;

    if (!date || date.length !== 2) return false;

    return true;
};

const startOfDay = (date: Date) => {
    const x = clone(date);
    x.setHours(0, 0, 0, 0);
    return x;
};

const endOfDay = (date: Date) => {
    const x = clone(date);
    x.setHours(23, 59, 59, 999);
    return x;
};

const startOfWeek = (date: Date) => {
    const x = clone(date);
    const dayOfWeek = x.getDay();
    const dayOfMonth = x.getDate();
    const diff = dayOfMonth - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is sunday
    x.setDate(diff);
    return startOfDay(x);
};

const endOfWeek = (date: Date) => {
    const x = clone(date);
    const dayOfWeek = x.getDay();
    const dayOfMonth = x.getDate();
    const diff = dayOfMonth - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is sunday
    x.setDate(diff + 6);
    return endOfDay(x);
};

const startOfMonth = (date: Date) => {
    return startOfDay(new Date(date.getFullYear(), date.getMonth(), 1));
};

const endOfMonth = (date: Date) => {
    const firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return new Date(firstDayOfNextMonth.getTime() - 1);
};

const startOfQuarter = (date: Date) => {
    const quarter = Math.floor(date.getMonth() / 3);
    return startOfDay(new Date(date.getFullYear(), quarter * 3, 1));
};

const endOfQuarter = (date: Date) => {
    const quarter = Math.floor(date.getMonth() / 3);
    return endOfDay(new Date(date.getFullYear(), quarter * 3 + 3, 0));
};

const startOfYear = (date: Date) => {
    return startOfDay(new Date(date.getFullYear(), 0, 1));
};

const endOfYear = (date: Date) => {
    return endOfDay(new Date(date.getFullYear(), 11, 31));
};

export default {
    clone,
    isDateString,
    isValidIsoDateTimeStringOrNull,
    isValidDate: function (date: Date): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    },
    isTimeString,
    getMonthsBetweenDates,
    getDaysBetweenDates,
    dateFormat,
    dateFormatUrlSafe,
    dateTimeFormat,
    /**
     * Incoming value is expected to be a UTC time in the format of '2020-02-27T11:54:30.493Z' or date object
     * Return YYYY-MM-DD
     */
    asOrderableDateString: function (d: Date | string): string {
        if (!d) return "";
        if (typeof d === "string") {
            if (!isValidIsoDateTimeStringOrNull(d)) {
                return "";
            }

            d = new Date(d);
        }

        const pad = (n: number) => String(n).padStart(2, "0");

        return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`;
    },
    /**
     * Incoming value is expected to be a UTC time in the format of '2020-02-27T11:54:30.493Z' or date object
     */
    asDateString: function (dateTime: Date | string): string {
        if (!dateTime) return "";

        let d: Date = null;

        if (typeof dateTime === "object") {
            if (isNaN(dateTime.getMonth())) return "";

            d = dateTime;
        } else {
            if (!isValidIsoDateTimeStringOrNull(dateTime)) {
                return "Invalid Date";
            }

            if (!dateTime) return "";

            d = new Date(dateTime);
        }

        return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    },
    /**
     * Incoming value is expected to be a UTC time in the format of '2020-02-27T11:54:30.493Z' or date object
     */
    asDateTimeString: function (dateTime: Date | string): string {
        let d: Date = null;

        if (typeof dateTime === "string") {
            if (!isValidIsoDateTimeStringOrNull(dateTime)) {
                return "Invalid Date";
            }

            if (!dateTime) return "";

            d = new Date(dateTime);
        } else {
            d = dateTime;
        }

        const pad = (num: number) => (num <= 9 ? "0" : "") + num;
        return (
            pad(d.getDate()) +
            "/" +
            pad(d.getMonth() + 1) +
            "/" +
            d.getFullYear() +
            " " +
            pad(d.getHours()) +
            ":" +
            pad(d.getMinutes())
        );
    },
    /**
     * Parses a date string in the format of "DD/MM/YYYY" into a JS Date
     */
    parseDate: (date?: string) => {
        const parts: string[] = date?.trim().split("/");
        if (parts.length !== 3) {
            return null;
        }

        const yearString = parts[2];

        if (yearString.length !== 4) return null;

        const year = parseInt(yearString);
        if (isNaN(year)) {
            return null;
        }

        const month = parseInt(parts[1]);
        if (isNaN(month) || month > 12 || month < 1) {
            return null;
        }

        const day = parseInt(parts[0]);

        if (isNaN(day) || day < 1) {
            return null;
        }

        const daysInMonth = {
            1: 31,
            2: year % 4 === 0 ? 29 : 28,
            3: 31,
            4: 30,
            5: 31,
            6: 30,
            7: 31,
            8: 31,
            9: 30,
            10: 31,
            11: 30,
            12: 31
        }[month];

        if (day > daysInMonth) {
            return null;
        }

        const result = new Date(year, month - 1, day);
        return isNaN(result.getDate()) ? null : result;
    },
    /**
     * Human readable elapsed or remaining time (example: 3 minutes ago)
     * @param  {Date|Number|String} date A Date object, timestamp or string parsable with Date.parse()
     * @param  {Date|Number|String} [nowDate] A Date object, timestamp or string parsable with Date.parse()
     * @param  {Intl.RelativeTimeFormat} [trf] A Intl formater
     * @return {string} Human readable elapsed or remaining time
     * @author github.com/victornpb
     * @see https://stackoverflow.com/a/67338038/938822
     */
    fromNow: function (
        isoDateString: string,
        rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })
    ): string {
        if (!isValidIsoDateTimeStringOrNull(isoDateString)) {
            logger.warn(`Invalid Date ${isoDateString} ${isoDateString.length}`);
            return "Invalid Date";
        }

        if (!isoDateString) return "";

        const date = new Date(isoDateString);
        const now = Date.now();
        const SECOND = 1000;
        const MINUTE = 60 * SECOND;
        const HOUR = 60 * MINUTE;
        const DAY = 24 * HOUR;
        const WEEK = 7 * DAY;
        const MONTH = 30 * DAY;
        const YEAR = 365 * DAY;
        const intervals = [
            { ge: YEAR, divisor: YEAR, unit: "year" },
            { ge: MONTH, divisor: MONTH, unit: "month" },
            { ge: WEEK, divisor: WEEK, unit: "week" },
            { ge: DAY, divisor: DAY, unit: "day" },
            { ge: HOUR, divisor: HOUR, unit: "hour" },
            { ge: MINUTE, divisor: MINUTE, unit: "minute" },
            { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
            { ge: 0, divisor: 1, text: "Just now" }
        ];

        const diff = now - date.getTime();
        const diffAbs = Math.abs(diff);
        for (const interval of intervals) {
            if (diffAbs >= interval.ge) {
                const x = Math.round(Math.abs(diff) / interval.divisor);
                const isFuture = diff < 0;
                return interval.unit ? rft.format(isFuture ? x : -x, interval.unit as any) : interval.text;
            }
        }
    },
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfQuarter,
    endOfQuarter,
    startOfYear,
    endOfYear,
    // Get Weeks within a range starting on the monday
    getWeeksInRange: (start: Date, end: Date) => {
        const weeks = [];
        const weekStart = startOfWeek(start);
        const weekEnd = endOfWeek(start);

        while (weekStart <= end) {
            weeks.push({
                start: clone(weekStart),
                end: clone(weekEnd)
            });

            weekStart.setDate(weekStart.getDate() + 7);
            weekEnd.setDate(weekEnd.getDate() + 7);
        }

        return weeks;
    }
};
