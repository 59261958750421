export default class DateFieldValue {
    public readonly date: Date;

    constructor(date: Date, public readonly hasTime: boolean) {
        this.date = date ?? null;
    }

    toSerializable() {
        return this.date?.toISOString();
    }

    exists(): boolean {
        return this.date != null && this.date instanceof Date && !isNaN(this.date.getTime());
    }

    toServerStringValue(): string {
        if (this.hasTime) {
            return this.exists() ? this.date.toISOString() : null;
        }

        return this.exists() ? getDateString(this.date) : null;

        function getDateString(d: Date) {
            const pad = (num: number) => (num <= 9 ? "0" : "") + num;
            return d.getFullYear() + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate());
        }
    }
}
