import React from "react";

export function StateJson(props: { label: string; state: any }) {
    return (
        <div>
            <h5>{props.label}</h5>
            <pre
                style={{
                    whiteSpace: "pre-wrap"
                }}>
                {JSON.stringify(props.state, null, 2)}
            </pre>
        </div>
    );
}
