import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import FormsApp from "./components/FormsApp";
import "./styles/intouch-form.scss";
import { http } from "@common";
import errorHandling from "./helpers/errorHandling";
import ErrorBoundary from "./components/ErrorBoundary";
import FormsAppConfigContext from "./state/FormsAppConfigContext";
import modal from "./modals/modal";
import configHelpers from "./helpers/configHelpers";
import Icon from "./styles/Icon";
import { FormAppEvents } from "./types";

(window as any)["intouchForm"] = {
    init: function (unsanitizedConfig: unknown) {
        const config = configHelpers.sanitize(unsanitizedConfig);

        const container = document.createElement("div");
        container.id = "intouch-forms";
        container.className = "itf-container";
        config.container.replaceChildren(container);

        http.init({
            baseUrl: config.serverUrl,
            modifyHeaders: headers => {
                if (config.token) {
                    headers["X-InTouch-Server-Token"] = config.token;
                }

                headers["obc"] = (new Date().getTime() * 13).toString();
            },
            on400: message => console.warn(message),
            on401: message => console.warn(message),
            on404: message => console.warn(message),
            onUnknownError: message => console.warn(message)
        });

        modal.init({ parent: document.body });

        errorHandling.startTracking(config.serverUrl, "InTouch Forms");

        const formAppProps = {
            formGuid: config.guid,
            scrollToFormTop: config?.functions?.scrollToFormTop ?? (() => container.scrollIntoView()),
            showPbl: !config.noPbl
        };

        createRoot(container).render(
            <React.StrictMode>
                <FormsAppConfigContext.Provider value={{ config }}>
                    <ErrorBoundary>
                        {config.isInMatterModal ? (
                            <InMatterModal events={config.events}>
                                <FormsApp {...formAppProps} />
                            </InMatterModal>
                        ) : (
                            <FormsApp {...formAppProps} />
                        )}
                    </ErrorBoundary>
                </FormsAppConfigContext.Provider>
            </React.StrictMode>
        );
    }
};

function InMatterModal(props: React.PropsWithChildren<any> & { events: FormAppEvents }) {
    const _onSubmitted = props.events.onSubmitted;
    const [isSubmitted, setIsSubmitted] = useState(false);

    props.events.onSubmitted = function () {
        setIsSubmitted(true);
        _onSubmitted && _onSubmitted();
    };

    const closeModal = () => {
        //https://github.com/davidjbradshaw/iframe-resizer library will have been loaded in the parent
        const send = (window as any).parentIFrame.sendMessage;
        send({
            eventName: "modal-close"
        });
    };

    return (
        <>
            {props.children}
            {!isSubmitted && (
                <a className="itf-button-parent-modal-close" onClick={closeModal}>
                    <Icon icon="faXmark" />
                </a>
            )}
        </>
    );
}
