import React, { useRef, useState } from "react";
import { MultiselectFieldValue, MultiselectQuestionSchema } from "../../types";
import useFieldState from "../../hooks/useFieldState";
import Checkbox from "../Checkbox";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

export default function MultiselectField(props: FieldProps<MultiselectQuestionSchema>) {
    const fs = useFieldState<MultiselectFieldValue>(props);

    const validationResult = fs.getValidationResult();

    const toggleEntry = (entry: string) => {
        fs.setValue(fs.value.toggleEntry(entry));
        fs.setIsTouched();
    };

    return (
        <>
            <FieldHeader {...props.schema} />
            <div>
                <ValidationMessage {...validationResult} />
            </div>
            {props.schema.options.map((opt, i) => (
                <Checkbox
                    key={i}
                    label={opt.label}
                    className={"itf-multi-select" + (validationResult.isInvalid ? " is-invalid" : "")}
                    value={fs.value.hasEntry(opt.value)}
                    disabled={props.disabled}
                    onChange={() => toggleEntry(opt.value)}
                    onBlur={() => fs.setIsTouched()}
                />
            ))}
            {props.schema.hasOtherOption && (
                <CheckboxWithTextField
                    value={fs.value}
                    disabled={props.disabled}
                    isInvalid={validationResult.isInvalid}
                    onChange={v => fs.setValue(v)}
                    onBlur={() => fs.setIsTouched()}
                />
            )}
        </>
    );
}

interface Props {
    value: MultiselectFieldValue;
    disabled: boolean;
    isInvalid: boolean;
    onChange: (value: MultiselectFieldValue) => void;
    onBlur: () => void;
}

function CheckboxWithTextField(props: Props) {
    const [checked, setChecked] = useState(!!props.value.otherValue);
    const inputRef = useRef<HTMLInputElement>(null);

    const toggleChecked = () => {
        setChecked(!checked);
        if (checked) {
            props.onChange(props.value.withOtherValue(""));
        } else {
            props.onChange(props.value.withOtherValue(inputRef.current?.value));
            setTimeout(() => inputRef.current?.focus(), 0);
        }
    };

    const handleTextChange = (otherValue: string) => {
        props.onChange(props.value.withOtherValue(otherValue));
    };

    return (
        <>
            <Checkbox
                label="Other"
                className={"itf-multi-select" + (props.isInvalid ? " is-invalid" : "")}
                value={checked}
                disabled={props.disabled}
                onChange={() => toggleChecked()}
                onBlur={props.onBlur}
            />
            <div className="itf-multi-select-other">
                <input
                    ref={inputRef}
                    type="text"
                    className={"itf-text-input " + (checked && props.isInvalid ? "is-invalid" : "")}
                    defaultValue={props.value.otherValue}
                    disabled={!checked || props.disabled}
                    onChange={e => handleTextChange(e.target.value)}
                    onBlur={props.onBlur}
                    placeholder="Specify"
                />
            </div>
        </>
    );
}
