import React from "react";
import { ModalActions, ModalComponentConstructor } from "./types";

interface Props extends ModalActions {
    component: ModalComponentConstructor;
    backdropStyle: React.CSSProperties;
}

export function ModalApp(props: Props) {
    const handleEscape = (e: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            props.onCancel();
        }
    };

    return (
        <>
            <div className="itf-modal-backdrop" style={props.backdropStyle}></div>
            <div className="itf-modal" tabIndex={-1} onKeyDown={handleEscape}>
                <div className="itf-modal-dialog">
                    <div className="itf-modal-content">{props.component(props)}</div>
                </div>
            </div>
        </>
    );
}
