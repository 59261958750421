export default class RadioListFieldValue {
    public readonly actualValue: string;

    constructor(actualValue: string, public readonly isOtherOption: boolean) {
        this.actualValue = actualValue ?? null;
    }

    toSerializable() {
        return { radioValue: this.actualValue, radioIsOther: this.isOtherOption };
    }

    exists(): boolean {
        return !!this.actualValue;
    }

    toServerStringValue(): string {
        return this.actualValue;
    }
}
