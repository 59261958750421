import React from "react";
import {
    BlockSchema,
    DropdownListFieldValue,
    FieldState,
    FormModel,
    FormState,
    QuestionSchema,
    RadioListFieldValue
} from "../types";
import Conditional from "./Conditional";
import Field from "./Field";
import { assertNever, preventDefault } from "@common";
import Quote from "./Quote";
import SafeHtml from "./SafeHtml";

interface BlockProps {
    block: BlockSchema;
    form: FormModel;
    formState: FormState;
    setFieldState: (dataMarker: string, fieldState: Partial<FieldState>) => void;
    html: string;
}

export default function Block(props: BlockProps) {
    return <div className={GetClassName()}>{Get()}</div>;

    function Get() {
        switch (props.block.type) {
            case "question":
                return (
                    <Conditional conditions={props.block.conditions} formState={props.formState}>
                        <>
                            <Field
                                schema={props.block.question}
                                formState={props.formState}
                                setFieldState={props.setFieldState}
                                currencySymbol={props.form.currencySymbol}
                            />
                            <ClearButton
                                question={props.block.question}
                                formState={props.formState}
                                setFieldState={props.setFieldState}
                            />
                        </>
                    </Conditional>
                );
            case "html":
                return (
                    <Conditional conditions={props.block.conditions} formState={props.formState}>
                        <SafeHtml html={props.html} />
                    </Conditional>
                );
            case "quote":
                return (
                    <Conditional conditions={props.block.conditions} formState={props.formState}>
                        <Quote quoteBlockSchema={props.block} form={props.form} formState={props.formState} />
                    </Conditional>
                );
            default:
                assertNever(props.block);
        }
    }

    function GetClassName() {
        switch (props.block.type) {
            case "question":
                const css = "itf-block itf-block-question";
                const dm = (props.block.question as any).dataMarker;
                if (dm) return `${css} ${dm}`;
                if (props.block.question.type === "address") {
                    return `${css} itf-block-address`;
                }
                return `itf-block itf-block-question`;
            case "html":
                return "itf-block itf-block-html";
            case "quote":
                return "itf-block itf-block-quote";
            default:
                assertNever(props.block);
        }
    }
}

interface ClearButtonProps {
    question: QuestionSchema;
    formState: FormState;
    setFieldState: (dataMarker: string, fieldState: Partial<FieldState>) => void;
}

function ClearButton(props: ClearButtonProps) {
    const question = props.question;

    if (question.type !== "radio-list" && question.type !== "dropdown-list") {
        return;
    }

    const { value } = props.formState[question.dataMarker];

    if (!value?.exists()) {
        return;
    }

    const newValue =
        question.type === "radio-list"
            ? new RadioListFieldValue(null, false)
            : question.type === "dropdown-list"
            ? new DropdownListFieldValue(null, false)
            : assertNever(question);

    return (
        <a
            href="#"
            onClick={preventDefault(() => {
                props.setFieldState(question.dataMarker, { value: newValue });
            })}>
            Clear
        </a>
    );
}
