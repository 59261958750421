const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export function handleNumberInputKeyboardEvent(e: React.KeyboardEvent<HTMLInputElement>): boolean {
    const input = e.target as HTMLInputElement;
    const currentValue = input.value;
    const position = input.selectionEnd;
    const containsMinus = currentValue.includes("-");
    const containsDot = currentValue.includes(".");
    const positionsDisallowedForDot = containsMinus ? [0, 1] : [0];

    //allow number after minus
    if (!containsMinus || position > 0) {
        if (numbers.includes(e.key)) {
            return true;
        }
    }

    //allow one negative sign at the start of the input
    if (position === 0 && !containsMinus && e.key === "-") {
        return true;
    }

    //allow one dot but not at the start of the input
    if (!positionsDisallowedForDot.includes(position) && !containsDot && e.key === ".") {
        return true;
    }

    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab") {
        return true;
    }

    if (e.key === "ArrowLeft" || e.key === "ArrowRight" || e.key === "Home" || e.key === "End") {
        return true;
    }

    //allow F5 (to refresh the page)
    if (e.key === "F5") {
        return true;
    }

    //allow Ctrl+A and cmd+A
    if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
        return true;
    }

    //allow Ctrl+C and cmd+C
    if (e.key === "c" && (e.ctrlKey || e.metaKey)) {
        return true;
    }

    //allow Ctrl+X and cmd+X
    if (e.key === "x" && (e.ctrlKey || e.metaKey)) {
        return true;
    }

    //allow Ctrl+V and cmd+V
    if (e.key === "v" && (e.ctrlKey || e.metaKey)) {
        return true;
    }

    //allow Ctrl+Z and cmd+Z
    if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
        return true;
    }

    e.preventDefault();
    //disallow everything else
    return false;
}
