import { FormModel, FormState, QuoteBlockSchema } from "@forms/types";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import formStateFn from "@forms/helpers/formStateFn";
import Loader from "../Loader";
import { useEffectOnFirstRender } from "@common";
import React, { Fragment, useState } from "react";
import { areAllConditionsSatisfied } from "@forms/helpers/conditionHelpers";
import { areAllPreviousAnswersValid } from "@forms/helpers/validationHelpers";
import SafeHtml from "../SafeHtml";
import { formatMoney } from "@forms/helpers/utils";
import Icon from "@forms/styles/Icon";

type QuoteServerModel = apiEmbeddedInTouchForms.Models.InTouchForms_Quote_QuoteModel;

interface Props {
    quoteBlockSchema: QuoteBlockSchema;
    form: FormModel;
    formState: FormState;
}

export default function Quote(props: Props) {
    const arePreviousAnswersValid = areAllPreviousAnswersValid(
        props.quoteBlockSchema,
        props.form.schema,
        props.formState
    );

    if (!arePreviousAnswersValid) {
        return (
            <>
                <label className="itf-label">Quote</label>
                <p className="itf-required">
                    Please provide valid answers to all previous questions to enable quote generation
                </p>
            </>
        );
    }

    return QuoteInner(props);
}

function QuoteInner(props: Props) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [quote, setQuote] = useState<QuoteServerModel>(null);
    const [noteToDisplayDataMarker, setNoteToDisplayDataMarker] = useState<string>(null);

    useEffectOnFirstRender(() => {
        const { data } = formStateFn.toCompletionData(props.formState, props.form.schema);

        const visibleFeeDataMarkers = props.quoteBlockSchema.fees
            .filter(f => areAllConditionsSatisfied(f.conditions ?? [], props.formState))
            .map(f => f.dataMarker);

        apiEmbeddedInTouchForms.EmbeddedInTouchForms.GetQuote(props.form.guid, { data, visibleFeeDataMarkers }).then(
            resp => {
                setDataLoaded(true);
                setQuote(resp.data);
            }
        );
    });

    if (!dataLoaded) return <Loader text="Calculating your estimate" />;

    if (!quote) return null;

    const displayNote = (dataMarker: string) => {
        setNoteToDisplayDataMarker(noteToDisplayDataMarker === dataMarker ? null : dataMarker);
    };

    return (
        <table className="itf-table">
            <thead>
                <tr>
                    <th>Fee</th>
                    <th>Cost</th>
                    <th>VAT</th>
                </tr>
            </thead>
            <tbody>
                {quote.lineItems.map((li, i) => (
                    <Fragment key={i}>
                        <tr>
                            <td>
                                {li.name}
                                {li.notes && (
                                    <span
                                        style={{ color: props.form.primaryColor }}
                                        className="itf-fee-note-display"
                                        onClick={() => displayNote(li.dataMarker)}>
                                        <Icon icon="faQuestionCircle" />
                                    </span>
                                )}
                            </td>
                            <td>{asCurrency(li.charge)}</td>
                            <td>{asCurrency(li.tax)}</td>
                        </tr>

                        {li.notes && noteToDisplayDataMarker === li.dataMarker && (
                            <tr>
                                <td colSpan={3} className="itf-fee-note">
                                    <SafeHtml html={li.notes} />
                                </td>
                            </tr>
                        )}
                    </Fragment>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td>Total Fees</td>
                    <td>{asCurrency(quote.totalCharge)}</td>
                    <td>{asCurrency(quote.totalTax)}</td>
                </tr>
                <tr>
                    <td>Grand Total</td>
                    <td></td>
                    <td>{asCurrency(quote.grandTotal)}</td>
                </tr>
            </tfoot>
        </table>
    );

    function asCurrency(value: number) {
        return props.form.currencySymbol + formatMoney(value);
    }
}
