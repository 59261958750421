import React from "react";
import modal from "./modal";
import Icon from "@forms/styles/Icon";

interface ConfirmOptions {
    title: string;
    body?: string | JSX.Element;
    cancelLabel?: string;
    confirmLabel?: string;
}

export default function confirmAsync(options: ConfirmOptions | string): Promise<boolean> {
    const defaultOptions: ConfirmOptions = {
        title: "Are you sure?",
        cancelLabel: "No",
        confirmLabel: "Yes"
    };

    const finalOptions =
        typeof options === "string" ? { ...defaultOptions, title: options } : { ...defaultOptions, ...options };

    return modal.create(({ onCancel, onConfirm }) => (
        <Confirm {...finalOptions} onCancel={onCancel} onConfirm={onConfirm} />
    ));
}

interface Props extends ConfirmOptions {
    onCancel: () => void;
    onConfirm: () => void;
}

function Confirm(props: Props) {
    return (
        <>
            <div className="itf-modal-header">
                <h2>{props.title}</h2>
            </div>
            {props.body && <div className="itf-modal-body">{props.body}</div>}
            <div className="itf-modal-footer">
                {props.cancelLabel && (
                    <button type="button" className="itf-button" onClick={props.onCancel}>
                        <Icon icon="faXmark" />
                        <span>{props.cancelLabel}</span>
                    </button>
                )}

                <button type="button" className="itf-button" onClick={props.onConfirm}>
                    <Icon icon="faCheck" />
                    <span>{props.confirmLabel}</span>
                </button>
            </div>
        </>
    );
}
