import React, { useState } from "react";
import { FieldState, FormState, FileFieldValue, FormModel } from "../types";
import formStateStorage from "../state/formStateStorage";
import { createFormState } from "../state/formState";

type SetFormState = React.Dispatch<React.SetStateAction<FormState>>;

export default function useFormState(
    form: FormModel,
    matterGuid: string,
    previousCompletionGuid: string,
    initialState: FormState
): [FormState, SetFormState, (dataMarker: string, fieldState: Partial<FieldState>) => void, () => Promise<void>] {
    const [formState, setFormState] = useState<FormState>(initialState);

    const newSetFormState = (setStateAction: React.SetStateAction<FormState>) => {
        if (typeof setStateAction === "function") {
            setFormState(prevState => {
                const newState = setStateAction(prevState);
                formStateStorage.save(form.guid, matterGuid, newState);
                return newState;
            });
        } else {
            setFormState(setStateAction);
            formStateStorage.save(form.guid, matterGuid, setStateAction);
        }
    };

    return [
        formState,
        newSetFormState,
        (dataMarker: string, fieldState: Partial<FieldState>) =>
            setFieldState(dataMarker, fieldState, form, matterGuid, newSetFormState),
        () => reset(form, matterGuid, previousCompletionGuid, setFormState)
    ];
}

const setFieldState = (
    dataMarker: string,
    fieldState: Partial<FieldState>,
    form: FormModel,
    matterGuid: string,
    setFormState: SetFormState
) => {
    setFormState(prevState => {
        const prevFieldState = prevState[dataMarker];

        return {
            ...prevState,
            [dataMarker]: {
                ...prevFieldState,
                ...fieldState
            }
        };
    });

    if (fieldState.value instanceof FileFieldValue) {
        // This is an asynchronous function called without await on purpose.
        // File selections are going to be rare and it's highly unlikely that file #2 will start upload
        // before file #1 has finished it. Therefore there's no need for complex syncing and promise-related plumbing
        formStateStorage.saveFile(form.guid, matterGuid, dataMarker, fieldState.value.files);
    }
};

const reset = async (
    form: FormModel,
    matterGuid: string,
    previousCompletionGuid: string,
    setFormState: (formState: FormState) => void
) => {
    formStateStorage.drop(form.guid, matterGuid);
    const [formState] = await createFormState(form, matterGuid, previousCompletionGuid);
    setFormState(formState);
};
