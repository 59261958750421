export default class FileFieldValue {
    public readonly files: readonly File[];

    constructor(files: readonly File[]) {
        this.files = files ?? [];
    }

    toSerializable(): null {
        return null;
    }

    exists(): boolean {
        return this.files.length > 0;
    }
}
