import React, { useContext, useState } from "react";
import { FieldState, FormModel, FormState, FormsAppConfig, StepSchema } from "@forms/types";
import { isStepValid } from "@forms/helpers/validationHelpers";
import { StateJson } from "@forms/components/StateJson";
import useFormState from "@forms/hooks/useFormState";
import { areAllConditionsSatisfied } from "@forms/helpers/conditionHelpers";
import { extractDataMarkersFromQuestion } from "@forms/helpers/questionFn";
import StepList from "./StepList";
import Step from "./Step";
import FinalStep from "./FinalStep";
import formStateFn from "@forms/helpers/formStateFn";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import confirmAsync from "../../modals/confirmAsync";
import alertAsync from "../../modals/alertAsync";
import { Dictionary, http } from "@common";
import { useStateObservable } from "@common";
import FormsAppConfigContext from "@forms/state/FormsAppConfigContext";
import formSchemaFn from "@forms/helpers/formSchemaFn";
import FormSubmissionError from "./FormSubmissionError";
import ClearAnswersButton from "./ClearAnswersButton";
import formStateStorage from "@forms/state/formStateStorage";
import Icon from "@forms/styles/Icon";

interface Props {
    form: FormModel;
    initialFormState: FormState;
    isDebug: boolean;
    scrollToFormTop: () => void;
    showPbl: boolean;
}

type Stage =
    | {
          name: "in-progress";
      }
    | {
          name: "success";
          state: FormState;
          matterGuid: string;
          chainedFormUrl: string;
      }
    | {
          name: "failure";
          message: string;
      };

export default function Form(props: Props) {
    const [stage, setStage] = useState<Stage>({ name: "in-progress" });

    const { config } = useContext(FormsAppConfigContext);
    const formStateProps = useFormState(
        props.form,
        config.matterGuid,
        config.previousCompletionGuid,
        props.initialFormState
    );
    const visibleSteps = props.form.schema.steps.filter(s =>
        areAllConditionsSatisfied(s.conditions ?? [], formStateProps[0])
    );
    const [currentStepIndex, setCurrentStepIndex] = useStateObservable(0, newStepIndex => {
        config.events?.onStepChange?.({ name: visibleSteps[newStepIndex].label });
        props.scrollToFormTop();
    });

    const [agreesToTermsOfService, setAgreesToTermsOfService] = useState(false);
    const [firstRenderTimeMs] = useState(Date.now());

    const restart = () => {
        setStage({ name: "in-progress" });
        setCurrentStepIndex(0);
    };

    switch (stage.name) {
        case "in-progress":
            return (
                <FormInner
                    form={props.form}
                    formStateProps={formStateProps}
                    visibleSteps={visibleSteps}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                    onStageChange={setStage}
                    agreesToTermsOfService={agreesToTermsOfService}
                    onAgreesToTermsOfServiceChange={setAgreesToTermsOfService}
                    firstRenderTimeMs={firstRenderTimeMs}
                    isDebug={props.isDebug}
                    showPbl={props.showPbl}
                />
            );
        case "success":
            return (
                <FinalStep
                    form={props.form}
                    formState={stage.state}
                    matterGuid={stage.matterGuid}
                    chainedFormUrl={stage.chainedFormUrl}
                />
            );
        case "failure":
            return <FormSubmissionError message={stage.message} onRestart={restart} />;
    }
}

interface FormInnerProps {
    form: FormModel;
    formStateProps: ReturnType<typeof useFormState>;
    visibleSteps: StepSchema[];
    currentStepIndex: number;
    setCurrentStepIndex: (index: number) => void;
    onStageChange: (stage: Stage) => void;
    agreesToTermsOfService: boolean;
    onAgreesToTermsOfServiceChange: (b: boolean) => void;
    firstRenderTimeMs: number;
    isDebug: boolean;
    showPbl: boolean;
}

function FormInner(props: FormInnerProps) {
    const { config } = useContext(FormsAppConfigContext);
    const [formState, setFormState, setFieldState, resetFormState] = props.formStateProps;
    const [hasRestoredAnswers, setHasRestoredAnswers] = useState(() =>
        formStateStorage.exists(props.form.guid, config.matterGuid, props.form.schema.steps)
    );

    const currentStep = props.visibleSteps[props.currentStepIndex];

    const reset = async () => {
        const result = await confirmAsync({
            title: "Are you sure you wish to reset?",
            body: <p>Any existing answers will be cleared.</p>
        });

        if (result) {
            props.setCurrentStepIndex(0);
            await resetFormState();
            setHasRestoredAnswers(false);
        }
    };

    const setTouchedOnCurrentStep = () => {
        const updatedStateEntries: [string, FieldState][] = currentStep.blocks
            .flatMap(b => (b.type === "question" ? [b.question] : []))
            .flatMap(q => extractDataMarkersFromQuestion(q))
            .map(questionMarker => [questionMarker, { ...formState[questionMarker], isTouched: true }]);

        const updatedStatePortion = Object.fromEntries(updatedStateEntries);

        setFormState({ ...formState, ...updatedStatePortion });
    };

    const setTouchedAndChangeStep = (newStepIndex: number) => {
        setTouchedOnCurrentStep();
        props.setCurrentStepIndex(newStepIndex);
    };

    const handleSubmit = async () => {
        const valid = props.visibleSteps.every(s => isStepValid(s, formState));
        if (!valid) {
            setTouchedOnCurrentStep();
            alertAsync("Please make sure all answers are valid before proceeding");
            return;
        }

        const completionData = getCompletionData(props.form, formState, config);
        const result = await tryCompleteForm(
            props.form,
            formState,
            config,
            completionData,
            props.agreesToTermsOfService,
            props.firstRenderTimeMs
        );

        if (result.success) {
            resetFormState();
            props.onStageChange({
                name: "success",
                state: formState,
                matterGuid: result.matterGuid,
                chainedFormUrl: result.chainedFormUrl
            });
            config.events?.onSubmitted?.(completionData.data, result.matterGuid);
        } else {
            props.onStageChange({
                name: "failure",
                message: result.message
            });
        }
    };

    const showSideMenu = props.visibleSteps.length > 1;

    return (
        <>
            {showSideMenu && (
                <div className="itf-side-menu">
                    <p className="itf-form-name">{props.form.name}</p>
                    <StepList
                        primaryColor={props.form.primaryColor}
                        secondaryColor={props.form.secondaryColor}
                        visibleSteps={props.visibleSteps}
                        currentStepIndex={props.currentStepIndex}
                        formState={formState}
                        onStepChange={setTouchedAndChangeStep}
                    />
                </div>
            )}
            <div className="itf-body">
                <div className="itf-step">
                    <h1 className="itf-step-heading" style={{ color: props.form.primaryColor }}>
                        {currentStep.label}
                    </h1>
                </div>
                {props.currentStepIndex === 0 && hasRestoredAnswers && (
                    <p className="itf-restored-answers">
                        <Icon icon="faInfoCircle" />
                        Your previous answers have been restored from last time. <ClearAnswersButton onClick={reset} />
                    </p>
                )}
                <Step
                    key={props.currentStepIndex}
                    step={currentStep}
                    stepIndex={props.currentStepIndex}
                    form={props.form}
                    formState={formState}
                    visibleSteps={props.visibleSteps}
                    setFieldState={setFieldState}
                    setAllTouched={setTouchedOnCurrentStep}
                    onPrevious={() => props.setCurrentStepIndex(props.currentStepIndex - 1)}
                    onNext={() => setTouchedAndChangeStep(props.currentStepIndex + 1)}
                    onSubmit={handleSubmit}
                    agreesToTermsOfService={props.agreesToTermsOfService}
                    onAgreesToTermsOfServiceChange={props.onAgreesToTermsOfServiceChange}
                />

                <ClearAnswersButton onClick={reset} />

                {props.showPbl && <Pbl />}
            </div>

            {props.isDebug && (
                <div className="itf-debug">
                    <StateJson label="Schema" state={props.form.schema} />
                    <StateJson label="Form State" state={formState} />
                </div>
            )}
        </>
    );
}

function getCompletionData(form: FormModel, formState: FormState, config: FormsAppConfig) {
    const completionData = formStateFn.toCompletionData(formState, form.schema);
    const submissionData = config.submissionData ?? {};
    const data = { ...submissionData, ...completionData.data };
    return { ...completionData, data };
}

async function uploadFiles(files: readonly File[]) {
    if (!files.length) {
        return {
            success: true,
            data: []
        };
    }

    return (await http.postFiles({
        url: apiEmbeddedInTouchForms.InTouchFormCompletion.UploadFiles_Url(),
        files: files
    })) as Awaited<ReturnType<typeof apiEmbeddedInTouchForms.InTouchFormCompletion.UploadFiles>>;
}

const tryCompleteForm: typeof completeForm = async (
    form,
    formState,
    config,
    completionData,
    agreesToTermsOfService,
    firstRenderTimeMs
) => {
    try {
        return await completeForm(form, formState, config, completionData, agreesToTermsOfService, firstRenderTimeMs);
    } catch {
        return {
            success: false,
            statusCode: null,
            message: null,
            matterGuid: null,
            chainedFormUrl: null
        };
    }
};

async function completeForm(
    form: FormModel,
    formState: FormState,
    config: FormsAppConfig,
    completionData: {
        data: Dictionary<string>;
        files: readonly File[];
        signatureBase64: string;
    },
    agreesToTermsOfService: boolean,
    firstRenderTimeMs: number
): Promise<{ success: boolean; message: string; matterGuid: string; chainedFormUrl: string }> {
    let visibleFeeDataMarkers: string[] = [];

    const quoteBlockSchema = formSchemaFn.findQuoteBlock(form.schema);

    if (quoteBlockSchema && areAllConditionsSatisfied(quoteBlockSchema.conditions ?? [], formState)) {
        visibleFeeDataMarkers = quoteBlockSchema.fees
            .filter(f => areAllConditionsSatisfied(f.conditions ?? [], formState))
            .map(f => f.dataMarker);
    }

    const filesResult = await uploadFiles(completionData.files);

    if (!filesResult.success) {
        return {
            success: filesResult.success,
            message: filesResult.message,
            matterGuid: null,
            chainedFormUrl: null
        };
    }

    const date = new Date(Date.now());
    const timeStamp =
        date.getDate() +
        " " +
        date.toLocaleString("en-US", { month: "long" }) +
        " " +
        date.getFullYear() +
        " " +
        date.toLocaleTimeString();

    const postData: apiEmbeddedInTouchForms.Models.InTouchForms_Completion_InTouchFormCompletionRequestModel = {
        visibleFeeDataMarkers,
        data: completionData.data,
        files: filesResult.data,
        signatureBase64: completionData.signatureBase64,
        timeStamp,
        agreesToTermsOfService,
        cnt: Math.floor((Date.now() - firstRenderTimeMs) / 1000),
        previousCompletionGuid: config.previousCompletionGuid
    };

    if (config.matterGuid) {
        const resp = await apiEmbeddedInTouchForms.InTouchFormCompletion.CompleteByUpdatingMatter(
            form.guid,
            config.matterGuid,
            postData
        );

        return {
            success: resp.message?.toLowerCase()?.includes("spam") ? false : resp.success,
            message: resp.message,
            matterGuid: resp.data?.matterGuid,
            chainedFormUrl: resp.data?.chainedFormLink
        };
    }

    const resp = await apiEmbeddedInTouchForms.InTouchFormCompletion.CompleteByCreatingMatter(form.guid, postData);

    return {
        success: resp.message?.toLowerCase()?.includes("spam") ? false : resp.success,
        message: resp.message,
        matterGuid: resp.data?.matterGuid,
        chainedFormUrl: resp.data?.chainedFormLink
    };
}

function Pbl() {
    return (
        //This link counts a backlink for SEO purposes, so we don't want to disable norefferer here
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href="https://www.intouch.cloud/pbl?utm_source=pbl&utm_medium=fw"
            target="_blank"
            title="InTouch Conveyancing Software"
            className="itf-pbl">
            InTouch
            <img
                src="https://static.intouch.cloud/images/InTouch-Logo-Flat.svg"
                alt="InTouch Conveyancing Software"
                height="24"
                width="24"
            />
        </a>
    );
}
