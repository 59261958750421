import { shortId } from "@common";
import React, { useState } from "react";
import { CurrencyInputValue } from "../components.types";
import { CurrencyQuestionSchema } from "../../types";
import useFieldState from "../../hooks/useFieldState";
import CurrencyInput from "../CurrencyInput";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

type Props = FieldProps<CurrencyQuestionSchema> & {
    currencySymbol: string;
};

export default function CurrencyField(props: Props) {
    const [id] = useState("currency-input-" + shortId());

    const fs = useFieldState<CurrencyInputValue>(props);

    const validationResult = fs.getValidationResult();

    return (
        <>
            <FieldHeader {...props.schema} id={id} />
            <CurrencyInput
                id={id}
                className={validationResult.isInvalid ? "is-invalid" : ""}
                value={fs.value}
                disabled={props.disabled}
                onChange={fs.setValue}
                onBlur={() => fs.setIsTouched()}
                currencySymbol={props.currencySymbol}
            />
            <ValidationMessage {...validationResult} />
        </>
    );
}
