export default class CheckboxFieldValue {
    public readonly actualValue: boolean;

    constructor(actualValue: boolean) {
        this.actualValue = actualValue ?? false;
    }

    toSerializable() {
        return this.actualValue;
    }

    exists(): boolean {
        return this.actualValue === true;
    }

    toServerStringValue(): string {
        return this.actualValue ? "Yes" : "No";
    }
}
