import { convertAddressQuestionToTextQuestions } from "@forms/helpers/questionFn";
import React, { useContext, useState } from "react";
import useFieldState from "src/forms/hooks/useFieldState";
import { AddressQuestionSchema, AddressServerModel, TextFieldValue } from "../../../types";
import FieldHeader from "../components/FieldHeader";
import FieldProps from "../FieldProps";
import AddressTextField from "./AddressTextField";
import AddressLookupUK from "./AddressLookupUK";
import AddressLookupAus from "./AddressLookupAus";
import FormsAppConfigContext from "@forms/state/FormsAppConfigContext";
import { preventDefault } from "@common";

export default function AddressField(props: FieldProps<AddressQuestionSchema>) {
    const { config } = useContext(FormsAppConfigContext);

    const addressFields = convertAddressQuestionToTextQuestions(props.schema).map(schema => ({
        schema,
        fieldState: useFieldState<TextFieldValue>({
            schema,
            disabled: props.disabled,
            formState: props.formState,
            setFieldState: props.setFieldState
        })
    }));

    const [addressFieldsVisible, setAddressFieldsVisible] = useState(
        addressFields.some(af => !!af.fieldState.value.actualValue)
    );

    const onAddressChange = (address: AddressServerModel) => {
        setAddressFieldsVisible(true);
        updateAddressFieldValue(addressFields[0].fieldState, address.address1);
        updateAddressFieldValue(addressFields[1].fieldState, address.address2);
        updateAddressFieldValue(addressFields[2].fieldState, address.city);
        updateAddressFieldValue(addressFields[3].fieldState, address.county);
        updateAddressFieldValue(addressFields[4].fieldState, address.postcode);
    };

    const updateAddressFieldValue = (fieldState: (typeof addressFields)[number]["fieldState"], value: string) => {
        fieldState.setValue(fieldState.value.update(value));
    };

    const clearAddress = () => {
        setAddressFieldsVisible(false);
        updateAddressFieldValue(addressFields[0].fieldState, null);
        updateAddressFieldValue(addressFields[1].fieldState, null);
        updateAddressFieldValue(addressFields[2].fieldState, null);
        updateAddressFieldValue(addressFields[3].fieldState, null);
        updateAddressFieldValue(addressFields[4].fieldState, null);
    };

    return (
        <>
            <FieldHeader {...props.schema} />
            {config.country === "AUS" ? (
                <AddressLookupAus
                    addressFieldsVisible={addressFieldsVisible}
                    disabled={props.disabled}
                    onChange={onAddressChange}
                    onBlur={() => addressFields.forEach(af => af.fieldState.setIsTouched())}
                    onEnterManually={() => setAddressFieldsVisible(true)}
                />
            ) : (
                <AddressLookupUK
                    addressFieldsVisible={addressFieldsVisible}
                    disabled={props.disabled}
                    onChange={onAddressChange}
                    onBlur={() => addressFields.forEach(af => af.fieldState.setIsTouched())}
                    onEnterManually={() => setAddressFieldsVisible(true)}
                />
            )}
            {addressFieldsVisible ? (
                <div>
                    <label className="itf-label">Address</label>
                    {addressFields.map((af, i) => (
                        <AddressTextField
                            key={i}
                            label={af.schema.label}
                            dataMarker={af.schema.dataMarker}
                            value={af.fieldState.value.actualValue}
                            isInvalid={af.fieldState.getValidationResult().isInvalid}
                            disabled={props.disabled}
                            onChange={value => af.fieldState.setValue(af.fieldState.value.update(value))}
                            onTouched={() => af.fieldState.setIsTouched()}
                        />
                    ))}
                    <a className="itf-address-button" href="#" onClick={preventDefault(clearAddress)}>
                        Clear Address
                    </a>
                </div>
            ) : (
                addressFields.some(af => af.fieldState.getValidationResult().isInvalid) && (
                    <div className="itf-invalid-answer">{props.schema.label} field is required</div>
                )
            )}
        </>
    );
}
