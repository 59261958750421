import { queryString } from "@common";
import React from "react";
import Form from "./Form";

interface Props {
    formGuid: string;
    scrollToFormTop: () => void;
    showPbl: boolean;
}

export default function FormsApp(props: Props) {
    const isDebug = queryString.has("debug");

    return <Form {...props} isDebug={isDebug} />;
}
