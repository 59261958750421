import HourPicker from "../HourPicker";
import MinutePicker from "../MinutePicker";
import { arrayFn } from "@common";
import React, { useEffect, useState } from "react";
import { DateFieldValue, DateQuestionSchema, DateTimeQuestionSchema } from "../../types";
import useFieldState from "../../hooks/useFieldState";
import FieldHeader from "./components/FieldHeader";
import FieldProps from "./FieldProps";
import ValidationMessage from "./components/ValidationMessage";

type DateFieldProps = FieldProps<DateQuestionSchema | DateTimeQuestionSchema> & {
    timeEnabled: boolean;
};

export default function DateField(props: DateFieldProps) {
    const fs = useFieldState<DateFieldValue>(props);

    const validationResult = fs.getValidationResult();

    const [day, setDay] = useState<number>(null);
    const [month, setMonth] = useState<number>(null);
    const [year, setYear] = useState(currentYear);
    const [hours, setHours] = useState<number>(null);
    const [minutes, setMinutes] = useState<number>(null);

    const daysInMonth = getDaysInMonth(month, year);

    const handleChange = (day: number, month: number, year: number, hours: number, minutes: number) => {
        const daysInMonth = getDaysInMonth(month, year);
        if (day > daysInMonth) {
            day = null;
        }

        setDay(day);
        setMonth(month);
        setYear(year);
        setHours(hours);
        setMinutes(minutes);

        const value = day && month && year ? new Date(year, month - 1, day, hours, minutes) : null;
        fs.setValue(new DateFieldValue(value, fs.value.hasTime));
        if (value) {
            fs.setIsTouched();
        }
    };

    useEffect(() => {
        if (!fs.value.date) {
            if (day && month && year) {
                setDay(null);
                setMonth(null);
                setYear(currentYear);
                setHours(0);
                setMinutes(0);
            }
            return;
        }

        setDay(fs.value.date.getDate());
        setMonth(fs.value.date.getMonth() + 1);
        setYear(fs.value.date.getFullYear());
        setHours(fs.value.date.getHours());
        setMinutes(fs.value.date.getMinutes());
    }, [fs.value]);

    return (
        <>
            <FieldHeader {...props.schema} />
            <div className={"itf-date-picker" + (props.timeEnabled ? " itf-time-picker" : "")}>
                <select
                    className={
                        "itf-select itf-month-select" + (validationResult.isInvalid && !month ? " is-invalid" : "")
                    }
                    value={month ?? ""}
                    disabled={props.disabled}
                    onChange={e => {
                        const month = e.target.value ? parseInt(e.target.value) : null;
                        handleChange(day, month, year, hours, minutes);
                    }}>
                    <option value="">Month</option>
                    {months.map(m => (
                        <option key={m.value} value={m.value}>
                            {m.name}
                        </option>
                    ))}
                </select>
                <select
                    className={"itf-select itf-day-select" + (validationResult.isInvalid && !day ? " is-invalid" : "")}
                    value={day ?? ""}
                    disabled={props.disabled}
                    onChange={e => {
                        const day = e.target.value ? parseInt(e.target.value) : null;
                        handleChange(day, month, year, hours, minutes);
                    }}>
                    <option value="">Day</option>
                    {days.map(d => (
                        <option key={d} value={d} style={{ display: daysInMonth < d ? "none" : "unset" }}>
                            {d}
                        </option>
                    ))}
                </select>
                <select
                    className={
                        "itf-select itf-year-select" + (validationResult.isInvalid && !year ? " is-invalid" : "")
                    }
                    value={year ?? ""}
                    disabled={props.disabled}
                    onChange={e => {
                        const year = e.target.value ? parseInt(e.target.value) : null;
                        handleChange(day, month, year, hours, minutes);
                        fs.setIsTouched();
                    }}
                    onBlur={fs.setIsTouched}>
                    <option value="">Year</option>
                    {years.map(y => (
                        <option key={y} value={y}>
                            {y}
                        </option>
                    ))}
                </select>

                {props.timeEnabled && (
                    <>
                        <HourPicker
                            className={validationResult.isInvalid ? "is-invalid" : ""}
                            value={hours}
                            disabled={props.disabled}
                            onChange={hours => {
                                handleChange(day, month, year, hours, minutes);
                                fs.setIsTouched();
                            }}
                            hasEmptyOption={true}
                        />
                        <span className={"itf-time-separator" + (validationResult.isInvalid ? " is-invalid" : "")}>
                            :
                        </span>
                        <MinutePicker
                            className={validationResult.isInvalid ? "is-invalid" : ""}
                            value={minutes}
                            disabled={props.disabled}
                            onChange={minutes => {
                                handleChange(day, month, year, hours, minutes);
                                fs.setIsTouched();
                            }}
                            minutesIncrement={5}
                            hasEmptyOption={true}
                        />
                    </>
                )}
            </div>

            {fs.value.date && (
                <div className="itf-date-time-summary">
                    {fs.value.date.toDateString()}{" "}
                    {props.timeEnabled && (
                        <>
                            {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}
                        </>
                    )}
                </div>
            )}
            <ValidationMessage {...validationResult} />
        </>
    );
}

const currentYear = new Date().getFullYear();

const days = arrayFn.range(1, 31);

const months = [
    { value: 1, name: "January" },
    { value: 2, name: "February" },
    { value: 3, name: "March" },
    { value: 4, name: "April" },
    { value: 5, name: "May" },
    { value: 6, name: "June" },
    { value: 7, name: "July" },
    { value: 8, name: "August" },
    { value: 9, name: "September" },
    { value: 10, name: "October" },
    { value: 11, name: "November" },
    { value: 12, name: "December" }
];

const years = arrayFn.range(currentYear - 200, currentYear + 100);

const getDaysInMonth = (month: number, year: number) => {
    return month && year ? new Date(year, month, 0).getDate() : 31;
};
