import { areAllConditionsSatisfied } from "../helpers/conditionHelpers";
import { ConditionSchema, FormState } from "../types";

interface ConditionalProps {
    conditions: ConditionSchema[];
    formState: FormState;
    children: JSX.Element;
}

export default function Conditional(props: ConditionalProps) {
    const { conditions, formState } = props;

    if (areAllConditionsSatisfied(conditions ?? [], formState)) {
        return props.children;
    }

    return null;
}
