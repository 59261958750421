import { Dictionary } from "@common";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import formSchemaFn from "@forms/helpers/formSchemaFn";
import { FormModel, TeamServerModel } from "@forms/types";

async function fetchTeamsIfNecessary(form: FormModel): Promise<TeamServerModel[]> {
    if (formSchemaFn.hasFeeEarnerQuestion(form.schema)) {
        const resp = await apiEmbeddedInTouchForms.EmbeddedInTouchForms.GetTeams(form.guid);
        return resp.data ?? [];
    }
    return [];
}

async function fetchMatterAnswerDataIfNecessary(formGuid: string, matterGuid: string): Promise<Dictionary<unknown>> {
    if (!matterGuid) {
        return {};
    }

    const resp = await apiEmbeddedInTouchForms.EmbeddedInTouchForms.GetMatterAnswers(formGuid, matterGuid);
    if (!resp.success) {
        return {};
    }

    const result = Object.fromEntries(resp.data.map(x => [x.dataMarker, x.answer]));
    return result;
}

async function fetchPreviousSubmissionDataIfNecessary(
    formGuid: string,
    matterGuid: string,
    previousCompletionGuid: string
) {
    if (!matterGuid || !previousCompletionGuid) return {};

    const resp = await apiEmbeddedInTouchForms.EmbeddedInTouchForms.GetPreviousCompletion(
        matterGuid,
        previousCompletionGuid,
        formGuid
    );

    if (!resp.success) {
        return {};
    }

    try {
        const submission = JSON.parse(resp.data.value);
        return submission?.request.data ?? {};
    } catch {
        return {};
    }
}

export default {
    fetchTeamsIfNecessary,
    fetchMatterAnswerDataIfNecessary,
    fetchPreviousSubmissionDataIfNecessary
};
