import { AddressQuestionSchema, FieldValue, QuestionSchema } from "../types";
import { validateField } from "../helpers/validationHelpers";
import { FieldProps } from "@forms/components/Field";

export default function useFieldState<TValue extends FieldValue>(
    props: FieldProps<Exclude<QuestionSchema, AddressQuestionSchema>>
) {
    const { isTouched, value } = props.formState[props.schema.dataMarker];

    return {
        value: value as TValue,
        setValue: (value: TValue) => props.setFieldState(props.schema.dataMarker, { value }),
        setIsTouched: () => props.setFieldState(props.schema.dataMarker, { isTouched: true }),
        getValidationResult() {
            if (isTouched) {
                return validateField(props.schema.required, value);
            }
            return { isInvalid: false };
        }
    };
}
