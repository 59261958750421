import React from "react";
import { assertNever } from "@common";
import { QuestionSchema } from "../../types";
import FieldProps from "./FieldProps";
import TextField from "./TextField";
import YesNoField from "./YesNoField";
import RadioListField from "./RadioListField";
import TextareaField from "./TextareaField";
import CurrencyField from "./CurrencyField";
import AddressField from "./AddressField";
import FileField from "./FileField";
import CheckboxField from "./CheckboxField";
import DropdownListField from "./DropdownListField";
import MultiselectField from "./MultiselectField";
import NumberField from "./NumberField";
import DateField from "./DateField";
import FieldDataMarker from "./components/FieldDataMarker";
import FeeEarnerField from "./FeeEarnerField";
import EsignatureField from "./EsignatureField";

type Props = FieldProps<QuestionSchema> & {
    currencySymbol: string;
};

export default function Field(props: Props) {
    const { schema, disabled, formState, setFieldState, currencySymbol } = props;
    const isBuilder = props.disabled;

    switch (schema.type) {
        case "text":
        case "email":
        case "phone-number":
        case "time":
            return (
                <>
                    <TextField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "yes-no":
            return (
                <>
                    <YesNoField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "textarea":
            return (
                <>
                    <TextareaField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "radio-list":
            return (
                <>
                    <RadioListField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "currency":
            return (
                <>
                    <CurrencyField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                        currencySymbol={currencySymbol}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "address":
            return (
                <AddressField schema={schema} disabled={disabled} formState={formState} setFieldState={setFieldState} />
            );
        case "file":
            return (
                <>
                    <FileField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "checkbox":
            return (
                <>
                    <CheckboxField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "dropdown-list":
            return (
                <>
                    <DropdownListField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "multiselect":
            return (
                <>
                    <MultiselectField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "number":
            return (
                <>
                    <NumberField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "date":
            return (
                <>
                    <DateField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                        timeEnabled={false}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "datetime":
            return (
                <>
                    <DateField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                        timeEnabled={true}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "fee-earner":
            return (
                <>
                    <FeeEarnerField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        case "esignature":
            return (
                <>
                    <EsignatureField
                        schema={schema}
                        disabled={disabled}
                        formState={formState}
                        setFieldState={setFieldState}
                    />
                    {isBuilder && <FieldDataMarker dataMarker={schema.dataMarker} />}
                </>
            );
        default:
            assertNever(schema);
    }
}
